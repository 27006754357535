import React, { useState, useEffect } from "react";
import UserInfo from "./components/UserInfo";
import { PropTypes } from "prop-types";
import "./header.scss";
import "./components/navbar.css";
import Notification from "./img/noti.png";
import Message from "./img/message.svg";
import Settings from "./img/settings.svg";
import { owner, presenter, seller } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { Notifications } from "react-push-notification";

const Header = props => {
  let history = useHistory();

  // useEffect(() => {
  //   //setSocket(io(`${REACT_APP_BACKEND_SERVER_URL}`));

  //   socket.on("connnection", () => {
  //     console.log("connected to server");
  //   });

  //   // socket.on("message", message => {
  //   //   if (!notifications.includes(message.mid)) {
  //   //     setnotifications([message.mid, ...notifications]);
  //   //   setMessages([...messages, message]);
  //   //   }
  //   // });

  //   socket.on("disconnect", () => {
  //     console.log("Socket disconnecting");
  //   });
  // }, []);
  return (
    <header className="Header">
      {/* <button className="Header-button">
        <i className="fa fa-chevron-left" />
        Menu
      </button> */}

      <h1 className="Header-pageTitle">{props.title}</h1>
      {/* <div className="icons">
        <div className="icon" onClick={() => setOpen(!open)}>
          <img src={Notification} className="iconImg" alt="" />
          {
notifications.length >0 &&
            <div className="counter">{notifications.length}</div>
          }
        </div>
    
      </div>
      {open && (
        <div className="notifications">
          {notifications.map((n) => displayNotification(n))}
          <button className="nButton" onClick={handleRead}>
            Mark as read
          </button>
        </div>
      )} */}
      <UserInfo
        image="http://loremflickr.com/60/60"
        userName={props.username}
        notifications={[
          { title: "Added new product" },
          { title: "Added new product" },
          { title: "Added new product" }
        ]}
      />
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  username: PropTypes.string
};

export default Header;
