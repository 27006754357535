import React from "react";
import { PropTypes } from "prop-types";
import cx from "classnames";

import "./box.scss";

// Content box
const Box = props => {
  return (
    <section className={cx("Box", props.className)}>{props.children}</section>
  );
};

Box.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string
};

export default Box;
