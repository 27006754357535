import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { ChatState } from "./socket";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const API_URL = "http://localhost:8080/api/auth/";
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user"))
);

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password
    })
    .then(response => {
      if (response.data.user.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        console.log(JSON.parse(localStorage.getItem("user")));
      }
      localStorage.removeItem("notifications");

      return response.data;
    });
};
const authenticateUser = (username, password) => {
  return axios
    .post(`https://api.virtualdealermanagement.com/api/Users/Authenticate`, {
      p_user_name: username,
      p_user_pass: password
    })
    .then(response => {
      if (response.data.user) {
        localStorage.setItem("notifications", JSON.stringify([]));
        localStorage.setItem("user", JSON.stringify(response.data.user));
        currentUserSubject.next(response.data.user);
      }
      return response;
    });
};

const authenticateToken = async response => {
  if (response.data.user) {
    await localStorage.setItem("notifications", JSON.stringify([]));
    await localStorage.setItem("user", JSON.stringify(response.data.user));
    await currentUserSubject.next(response.data.user);
  }
  return response;
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("notifications");
  currentUserSubject.next(null);
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  authenticateUser,
  authenticateToken,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

export default AuthService;
