import React, { useState, useEffect } from "react";
import Axios from "axios";
import { PropTypes } from "prop-types";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import MyEmail from "../Email/Email";
import { renderEmail } from "react-html-email";
import * as constantsClass from "../../utils/constants";
import * as sendEmail from "../../utils/sendEmail";
import "dotenv/config";
import handleResponse from "../../services/handle-response";
import AuthService from "../../services/auth.service";
const { REACT_APP_BACKEND_SERVER_URL } = process.env;
const PendingPresentations = props => {
  let history = useHistory();

  const user = AuthService.currentUserValue;
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };
  const [data, setdata] = useState("");
  const [presentationLink, setPresentationLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [presenterID, setPresenterID] = useState(user.user_id);
  const [presenterName, setPresenterName] = useState(user.username);
  const getDataset = () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/searchPresentations`,
        {
          p_returnstats: false,
          p_returndataset: true,
          p_presentation_status: "In-Progress",
          p_seller_id: "",
          p_dealerName: "",
          p_presenter_id: user.user_id,
          p_presenter_name: "",
          p_startDate: "",
          p_endDate: "",
          p_stockno: ""
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(
        response => {
          console.log(response);
          setdata(response.data.results[0]);

          //console.log(temp);
        },
        error => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(_content);
          //setContent(_content);

          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            AuthService.logout();
            history.push("/login");
            //EventBus.dispatch("logout");
          }
        }
      );
    } catch (error) {
      console.log(error);
      console.error(error.message);

      if ([401, 403].indexOf(error) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // authenticationService.logout();
        console.log("unauthorized");
        //location.reload(true);
      }

      // const error = (data && data.message) || response.statusText;
      // return Promise.reject(error);
    }
  };
  const [orders, setOrders] = useState([]);

  // useEffect(() => {
  //   const socket = io('http://localhost:8000')

  //   socket.on('connnection', () => {
  //     console.log('connected to server');
  //   })

  //   socket.on('order-added', (newOrders) => {
  //     setOrders(newOrders)
  //   })

  //   socket.on('message', (message) => {
  //     console.log(message);
  //   })

  //   socket.on('disconnect', () => {
  //     console.log('Socket disconnecting');
  //   })

  // }, [])
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log(selectedOption);
      getDataset();
      setLoading(false);
    };

    fetchData();
  }, [selectedOption]);

  const columns = [
    {
      name: "Customer Name",
      selector: row => row.customer_name
    },
    {
      name: "Stock #",
      selector: row => row.stockNo
    },
    {
      name: "Year",
      selector: row => row.year
    },
    {
      name: "Make",
      selector: row => row.make
    },
    {
      name: "Model",
      selector: row => row.model
    },
    {
      name: "Mileage",
      selector: row => row.miles
    },
    {
      name: "Cost",
      selector: row => row.cost
    },
    {
      name: "Profit",
      selector: row => row.profit
    },
    {
      name: "Presentation Date",
      selector: row => row.order_date
    },
    {
      name: "Action",
      cell: row => (
        <input
          className="Form-button"
          type="button"
          value="Join"
          onClick={() => {
            acceptPresentation(row.order_id, row.presentation_link);
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];
  const handleChange = e => {
    setSelectedOption(e.target.value);
  };
  const acceptPresentation = async (presentationID, presentationLink) => {
    console.log(presentationID, presentationLink);

    Axios.post(
      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/CheckStatus`,
      {
        p_order_id: presentationID
      },
      { headers: { "x-access-token": user.accessToken } }
    ).then(
      responseCheckStatus => {
        console.log(responseCheckStatus);
        if (responseCheckStatus.data.success) {
          if (responseCheckStatus.data.details) {
            //if the param details is there
            console.log(responseCheckStatus.data.details);
            let presentationdetails = responseCheckStatus.data.details;
            if (presentationdetails.status === 1) {
              // the presentation is still pending someone needs to accept
              // this is unlikely cuz we are showing only in-progress presentations by same presenter
            } else if (presentationdetails.status === 2) {
              if (presentationdetails.presenter_id == user.user_id) {
                //if its my presentation join it // this is silly cuz we are only getting presentation with this user id
                let path = `/Presentation/${presentationLink}/${user.username}`;
                history.push({
                  pathname: path,

                  state: { p_order_id: presentationID, p_user_role: user.roles }
                });
              } else {
                alert("Cant accept presentation");
              }
            }
          }
        } else {
          alert(responseCheckStatus.data.message);
        }

        //console.log(temp);
      },
      error => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(_content);
        //setContent(_content);

        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status === 403)
        ) {
          AuthService.logout();
          history.push("/login");
          //EventBus.dispatch("logout");
        }
      }
    );

    // const responseCheckStatus = await Axios.post(
    //   `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/CheckStatus`,
    //   {
    //     p_order_id: presentationID
    //   }
    // );

    // if (responseCheckStatus.data.success) {
    //   if (responseCheckStatus.data.details) {
    //     //if the param details is there
    //     console.log(responseCheckStatus.data.details);
    //     let presentationdetails = responseCheckStatus.data.details;
    //     if (presentationdetails.status === 1) {
    //       // the presentation is still pending someone needs to accept
    //       // this is unlikely cuz we are showing only in-progress presentations by same presenter
    //     } else if (presentationdetails.status === 2) {
    //       //since this  the presentation is inprogress, check if i am able to join the presentation, if i accepted and left it by chance
    //       // const response = await Axios.post(
    //       //   `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Accept`,
    //       //   {
    //       //     p_order_id: presentationID,
    //       //     p_presenter_id: presenterID
    //       //   }
    //       // );

    //       if (presentationdetails.presenter_id == user.user_id) {
    //         //if its my presentation join it // this is silly cuz we are only getting presentation with this user id
    //         let path = `/Presentation/${presentationLink}/${user.username}`;
    //         history.push({
    //           pathname: path,

    //           state: { p_order_id: presentationID, p_user_role: user.roles }
    //         });
    //       } else {
    //         alert("Cant accept presentation");
    //       }
    //     }
    //   }
    // } else {
    //   alert(responseCheckStatus.data.message);
    // }
  };
  return (
    <div>
      <Box>
        <div className="row">
          <div className="col-md-8">
            <h3>Left Presentation</h3>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </Box>
    </div>
  );
};

export default PendingPresentations;
