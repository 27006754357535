import React, { useState, useEffect } from "react";

import Axios from "axios";

import Box from "../../bootstrap_components/Box";

import DataTable from "react-data-table-component";

import { useHistory } from "react-router-dom";

import * as constantsClass from "../../utils/constants";

import "dotenv/config";


import AuthService from "../../services/auth.service";

const { REACT_APP_BACKEND_SERVER_URL, REACT_APP_EMAIL_FOOTER, REACT_APP_FRONTEND_SERVER_URL } = process.env;


const ActivateUsers = props => {

  let history = useHistory();

  //  Internally, customStyles will deep merges your customStyles with the default styling.

  const customStyles = {

    headCells: {

      style: {

        paddingLeft: "8px", // override the cell padding for head cells

        paddingRight: "8px",

        fontWeight: "bold"

      }

    }

  };

  const user = AuthService.currentUserValue;


  const [data, setData] = useState("");

  const [userType, setUserType] = useState("");

  const [fullName, setFullName] = useState("");

  const [email, setEmail] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [phoneCarrier, setPhoneCarrier] = useState("");

  const [address, setAddress] = useState("");

  const [pocName, setPocName] = useState("");

  const [accountEmail, setAccountEmail] = useState("");

  const [accountContact, setAccountContact] = useState("");

  const [escCompany, setESCCompany] = useState("");

  const [escCompanyCostSheet, setESCCompanyCostSheet] = useState("");

  const [gapCompany, setGapCompany] = useState("");

  const [gapCostSheet, setGAPCostSheet] = useState("");

  const [otherProducts, setOtherProducts] = useState("");

  const [otherProductsCostSheet, setOtherProductsCostSheet] = useState("");

  const [authorizedSMS, setAuthorizedSMS] = useState("");

  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");

  const [depositID, setDepositID] = useState(1);

  const [depositType, setDepositType] = useState("");

  const [bankAccountNumber, setBankAccountNumber] = useState("");

  const [bankRoutingNumber, setBankRoutingNumber] = useState("");

  const [bankAddress, setBankAddress] = useState("");

  const [bankAccountTitle, setBankAccountTitle] = useState("");

  const [addressOnBankAccount, setAddressOnBankAccount] = useState("");

  const [walletAddress, setWalletAddress] = useState("");

  const [mailingAddress, setMailingAddress] = useState("");

  const [identityType, setIdentityType] = useState(0);

  const [identityFrontSide, setIdentityFrontSide] = useState("");

  const [identityBackSide, setIdentityBackSide] = useState("");

  const [w9Form, setw9Form] = useState("");


  const [isNotDealer, setIsNotDealer] = useState(true);

  const [isNotPresenter, setIsNotPresenter] = useState(true);

  const [isNotBank, setIsNotBank] = useState(false);

  const [isNotCheque, setIsNotCheque] = useState(true);

  const [isNotCrypto, setIsNotCrypto] = useState(true);

  const [emailActivationSubject, setEmailActivationSubject] = useState("");

  const [emailActivationContent, setEmailActivationContent] = useState("");

  //const [presenterName, setPresenterName] = useState(user.username);


  const fetchData = async () => {

    try {

      const response = await Axios.get(

        `${REACT_APP_BACKEND_SERVER_URL}/api/Users/Pending`,
        { headers: { "x-access-token": user.accessToken } }

      );

      setData(response.data.results[0]);

    } catch (error) {

      console.error(error.message);

    }

  };

  useEffect(() => {

    fetchData();

    return () => {};

  }, []);



  const columns = [

    {

      name: "User Type",

      selector: row => row.role_name

    },

    {

      name: "Full Name",

      selector: row => row.full_name

    },

    {

      name: "Email",

      selector: row => row.user_email

    },

    {

      name: "Email Status",

      selector: row => row.email_status

    },

    {

      name: "Phone Number",

      selector: row => row.phone_number

    },

    {

      name: "Phone Carrier",

      selector: row => row.phone_carrier

    },

    {

      name: "Action",

      cell: row => (

        <div>

          <a

            className="fa fa-eye view-button"

            type="button"

            title="View User Details"

            onClick={() => {

              setFullName(row.full_name);

              setEmail(row.user_email);

              setUserType(row.role_name);

              setPhoneNumber(row.phone_number);

              setPhoneCarrier(row.phone_carrier);

              GetUserDetails(row.user_id, row.role_id);

            }}

            ></a>&nbsp;&nbsp;&nbsp;

          <a

            className="fa fa-user-plus add-button"

            type="button"

            title="Activate User"

            onClick={() => {

              ActivateUser(row.user_id, row.role_id, row.full_name, row.user_email);     

            }}

          ></a>

        </div>

      ),

      ignoreRowClick: true,

      allowOverflow: true,

      button: true

    }

  ];

  const getEmailContent = async () => {

    try {

      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Email/getContent`, {

        p_content_id: 5

      }).then(response => {

          setEmailActivationSubject(response.data.results[0][0].email_subject);

          setEmailActivationContent(response.data.results[0][0].email_content_text);

        //console.log(temp);

      });

    } catch (error) {

      console.error(error.message);

    }

  };

  const sendEmailVerify = (fullName, email) => {

    try {

      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/send/email`, {

        p_to_email: email,

        p_email_subject: emailActivationSubject,

        p_email_content: `DEAR <strong>${fullName}</strong>, <br/><br/> ${emailActivationContent} <br/> <a href="${REACT_APP_FRONTEND_SERVER_URL}" target="_blank">CLICK HERE TO LOGIN</a><br/><br/><br/>${REACT_APP_EMAIL_FOOTER}`

      }).then(response => {

        //console.log(temp);

      });

    } catch (error) {

      console.error(error.message);

    }


   

  };


  const SetUserType = async (roleID) => {

    if(roleID == 2){

      setIsNotPresenter(true);

      setIsNotDealer(false);

    }else{

      setIsNotPresenter(false);

      setIsNotDealer(true);

    }

  };


  const SetDepositFields = async (depositID) => {

    if(depositID == 1){

      setIsNotBank(false);

      setIsNotCheque(true);

      setIsNotCrypto(true);

    }else if(depositID == 2){

      setIsNotCheque(false);

      setIsNotBank(true);

      setIsNotCrypto(true);

    }else{

      setIsNotCrypto(false);

      setIsNotBank(true);

      setIsNotCheque(true);

    }

  };


  const ActivateUser = async (userID, roleID, fullName, email) => {

    // console.log(presentationLink)

    const response = await Axios.post(

      `${REACT_APP_BACKEND_SERVER_URL}/api/Users/UpdateStatus`,

      {

        p_user_id: userID,

        p_role_id: roleID,

        p_user_status: 1,

        p_modified_by: user.user_id

      },
      { headers: { "x-access-token": user.accessToken } }

    );

    if(response.data.success){

      sendEmailVerify(fullName, email);

      fetchData();

    }

    alert(response.data.message);

    

  };


  const GetUserDetails = async (userID, roleID) => {

    try {

      SetUserType(roleID);

      const response = await Axios.post(

        `${REACT_APP_BACKEND_SERVER_URL}/api/User/Details`,

        {

          p_user_id: userID,

          p_role_id: roleID

        },
        { headers: { "x-access-token": user.accessToken } }

      ).then(response => {

      if(response.data.success){

        var result = response.data.results[0][0];

        if(roleID == 2){        

          setAddress(result.dealer_address);

          setPocName(result.dealer_poc_name);

          setPocName(result.dealer_poc_name);

          setAccountEmail(result.dealer_account_email);

          setAccountContact(result.dealer_account_contact);

          setESCCompany(result.dealer_esc_company);

          setESCCompanyCostSheet(result.dealer_esc_cost_sheet);

          setGapCompany(result.dealer_gap_company);

          setGAPCostSheet(result.dealer_gap_cost_sheet);

          setOtherProducts(result.dealer_other_products);

          setOtherProductsCostSheet(result.dealer_other_products_cost_sheet);

        }else{

          SetDepositFields(result.deposit_id);

          setSocialSecurityNumber(result.presenter_social_sec_number);

          setDepositType(result.deposit_name);

          setBankAccountNumber(result.presenter_bank_account_number);

          setBankRoutingNumber(result.presenter_bank_routing_number);

          setBankAddress(result.presenter_bank_address);

          setBankAccountTitle(result.presenter_bank_account_title);

          setAddressOnBankAccount(result.presenter_address_on_bank_account);

          setWalletAddress(result.presenter_wallet_address);

          setMailingAddress(result.presenter_mailing_address);

          setIdentityType(result.identity_type_name);

          setIdentityFrontSide(result.presenter_identity_type_front);

          setIdentityBackSide(result.presenter_identity_type_back);

          setw9Form(result.presenter_w9_form);

          

          setAuthorizedSMS(result.presenter_authorized_text==1 ? "Allowed" : "Not Allowed");

        }

      }

      else{

        alert(response.data.message)

      }

    })

    } catch (error) {

      console.error(error.message);

    }

  };

  useEffect(() => {

    fetchData();

    getEmailContent();

    return () => {};

  }, []);

  // useEffect(() => {

  //   console.log("address", address)

  // }, [address, pocName]);


  return (

    <div>

      <Box>

        <DataTable

          title="Pending Users for Activation"

          columns={columns}

          data={data}

          pagination

          customStyles={customStyles}

        />

        <div className="row" hidden = {isNotDealer && isNotPresenter}>

          <form className="Form col-md-12">

            

              <div className="row">

                <div className="col-md-12 ">

                  <label className="Box-title Box--orange">User Info</label>

                  <Box>

                  <div className="row">       

                    <div className="col-md-12">

                      <div className="form-group">

                          <p>&nbsp;&nbsp;</p> 

                      </div>

                    </div>        

                    <div className="col-md-4">

                      <div className="form-group">

                          <p><b>User Type:</b>&nbsp;&nbsp;{userType}</p> 

                      </div>

                    </div>            

                    <div className="col-md-4">

                      <div className="form-group">

                          <p><b>Full Name:</b>&nbsp;&nbsp;{fullName}</p> 

                      </div>

                    </div>            

                    <div className="col-md-4">

                      <div className="form-group">

                          <p><b>Email:</b>&nbsp;&nbsp;{email}</p> 

                      </div>

                    </div>           

                    <div className="col-md-4">

                      <div className="form-group">

                          <p><b>Phone Number:</b>&nbsp;&nbsp;{phoneNumber}</p> 

                      </div>

                    </div>           

                    <div className="col-md-4">

                      <div className="form-group">

                          <p><b>Phone Carrier:</b>&nbsp;&nbsp;{phoneCarrier}</p> 

                      </div>

                    </div>       


                    <div className="col-md-4">

                      <div className="form-group"

                        hidden = {isNotDealer}>

                          <p><b>Address:</b>&nbsp;&nbsp;{address}</p> 

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>POC Name:</b>&nbsp;&nbsp;{pocName}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>Accounting Email:</b>&nbsp;&nbsp;{accountEmail}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>Accounting Contact Person:</b>&nbsp;&nbsp;{accountContact}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>ESC Company:</b>&nbsp;&nbsp;<a href={`${escCompanyCostSheet}`} target="_blank">{escCompany}</a></p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>GAP Company:</b>&nbsp;&nbsp;<a href={`${gapCostSheet}`} target="_blank">{gapCompany}</a></p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotDealer}>

                      <div className="form-group">

                          <p><b>Other Products:</b>&nbsp;&nbsp;<a href={`${otherProductsCostSheet}`} target="_blank">{otherProducts}</a></p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter}>

                      <div className="form-group">

                          <p><b>Deposit Type:</b>&nbsp;&nbsp;{depositType}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotBank}>

                      <div className="form-group">

                          <p><b>Bank Account Number:</b>&nbsp;&nbsp;{bankAccountNumber}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotBank}>

                      <div className="form-group">

                          <p><b>Routing Number:</b>&nbsp;&nbsp;{bankRoutingNumber}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotBank}>

                      <div className="form-group">

                          <p><b>Bank Address:</b>&nbsp;&nbsp;{bankAddress}</p>

                      </div>

                    </div>

      

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotBank }>

                      <div className="form-group">

                          <p><b>Bank Account Title:</b>&nbsp;&nbsp;{bankAccountTitle}</p>

                      </div>

                    </div>

      

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotBank}>

                      <div className="form-group">

                          <p><b>Address on Bank Account:</b>&nbsp;&nbsp;{addressOnBankAccount}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotCheque}>

                      <div className="form-group">

                          <p><b>Mailing Address:</b>&nbsp;&nbsp;{mailingAddress}</p>

                      </div>

                    </div>

      

                    <div className="col-md-4"

                          hidden = {isNotPresenter || isNotCrypto}>

                      <div className="form-group">

                          <p><b>Wallet Address:</b>&nbsp;&nbsp;{walletAddress}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter}>

                      <div className="form-group">

                          <p><b>Identity Type:</b>&nbsp;&nbsp;{identityType}&nbsp;&nbsp;<a href={`${identityFrontSide}`} target="_blank">Front Side</a>&nbsp;&nbsp;<a href={`${identityBackSide}`} target="_blank">Back Side</a></p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter}>

                      <div className="form-group">

                          <p><b>Social Security Number:</b>&nbsp;&nbsp;{socialSecurityNumber}</p>

                      </div>

                    </div>

                    

                    <div className="col-md-4"

                          hidden = {isNotPresenter}>

                      <div className="form-group">

                          <p><b>W-9 Form:</b>&nbsp;&nbsp;<a href={`${w9Form}`} target="_blank">View</a></p>

                      </div>

                    </div>

                    <div className="col-md-4"

                          hidden = {isNotPresenter}>

                      <div className="form-group">

                          <p><b>SMS notifications:</b> {authorizedSMS}</p>

                      </div>

                    </div>

      

                  </div>

                  </Box>

                </div>

              </div>

          </form>

          </div>

      </Box>

    </div>

  );

};


export default ActivateUsers;
