import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import SummaryBoxRow from "./SummaryBoxRow";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { Chart } from "react-google-charts";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Axios from "axios";
import { subDays } from "date-fns";
import * as constantsClass from "../../utils/constants";
import "dotenv/config";
import moment from "moment";

import AuthService from "../../services/auth.service";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const OwnerDashboard = props => {
  const [Loading, SetLoading] = useState(true);
  const [Revevnue, SetRevenue] = useState("0");
  const [ActiveSellers, SetActiveSellers] = useState("0");
  const [ActivePresenters, SetActivePresenters] = useState("0");
  const [Value, OnChange] = useState([subDays(new Date(), 30), new Date()]);
  const [tableData, settableData] = useState([]);
  const [currentMonth, setcurrentMoth] = useState(moment().format("MMMM-YYYY"));

  const user = AuthService.currentUserValue;
  //console.log(user);
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };
  const divStyle = {
    marginRight: "20px",
    marginLeft: "20px"
  };
  const columns = [
    {
      name: "#",
      selector: row => row.item
    },
    {
      name: "Today",
      selector: row => row.today
    },
    {
      name: "Yesterday",
      selector: row => row.yesterday
    },
    {
      name: "Last Week",
      selector: row => row.week
    },
    {
      name: "MTD",
      selector: row => row.mtd
    }
  ];
  const data = [
    {
      item: "Presentations",
      today: "12",
      yesterday: "18",
      week: "34",
      mtd: "89"
    },
    {
      item: "Gorss Profit",
      today: "12",
      yesterday: "18",
      week: "34",
      mtd: "89"
    },
    {
      item: "Net Profit",
      today: "12",
      yesterday: "18",
      week: "34",
      mtd: "89"
    },
    {
      item: "Commision",
      today: "12",
      yesterday: "18",
      week: "34",
      mtd: "89"
    },
    ,
    {
      item: "Active Sellers",
      today: "2",
      yesterday: "3",
      week: "3",
      mtd: "5"
    },
    ,
    {
      item: "Active Presenters",
      today: "4",
      yesterday: "6",
      week: "6",
      mtd: "7"
    }
  ];
  const dynamicData = [];
  const SearchPresentations = () => {
    getStats(
      Value[0].toISOString().split("T")[0],
      Value[1].toISOString().split("T")[0]
    );
  };
  const selectionRange = {
    startDate: subDays(new Date(), 90),
    endDate: new Date(),
    key: "selection"
  };
  const handleSelect = date => {
    console.log(date); // native Date object
  };

  const getStats = (pSDate, pEDate) => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/ownerPresentation`,
        {
          p_startDate: pSDate,
          p_endDate: pEDate
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(response => {
        // console.log();
        SetActivePresenters(response.data.results[0][0].active_presenters);
        SetActiveSellers(response.data.results[0][0].active_dealers);
        SetRevenue(response.data.results[0][0].revenue);
        let temp = [];
        //temp.push(["#","Today","Yesterday","Last Week", "MTD", { role: "style" }]);
        let dictkey = ["item", "today", "yesterday", "week", "mtd"];
        let keys = [
          "count",
          "profit",
          "gross_profit",
          "presenter_commision",
          "net_profit",
          "presenter",
          "seller"
        ];
        let columns = [
          "Presentation",
          "Revenue",
          "Gross Profit",
          "Commission",
          "Net Profit",
          "Active Presenters",
          "Active Sellers"
        ];

        keys.forEach((key, i) => {
          let toadd = {};
          //toadd.push(columns[i]);
          toadd[dictkey[0]] = columns[i];
          response.data.results.forEach((item, index) => {
            if (index != 0 && index != response.data.results.length - 1) {
              //console.log(item);
              toadd[dictkey[index]] = item[0][key] === null ? 0 : item[0][key];
              //toadd.push(item[0][key]=== null ? 0 : item[0][key]);
            }

            // Prints the index at which the loop is currently at
          });
          temp.push(toadd);
        });
        settableData(temp);
        // console.log(temp);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      SetLoading(true);
      getStats("", "");
      SetLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Box>
        <div className="row">
          <div className="col-md-12">
            <label className="Box-title Box--orange">
              Platform Overview - ({currentMonth})
            </label>
            <Box>
              <div className="row">
                <div className="col-md-3">
                  <Box className="SummaryBox Box--red BoxSpace">
                    <h2 className="SummaryBox-title">Total Revenue</h2>

                    <strong className="SummaryBox-value">
                      {Revevnue || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--blue BoxSpace">
                    <h2 className="SummaryBox-title">Active Sellers</h2>

                    <strong className="SummaryBox-value">
                      {ActiveSellers || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--green BoxSpace">
                    <h2 className="SummaryBox-title">Active Presenters</h2>

                    <strong className="SummaryBox-value">
                      {ActivePresenters || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-3">
            <strong>Date Range</strong>
            <DateRangePicker onChange={onChange} value={value} />
          </div>
          <div className="col-md-3">
            <strong>Stock #</strong>
            <input
              className="Form-field"
              type="text"
              name="stockNumber"
              onChange={e => {
                setStockNumber(e.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <strong>Presenter</strong>
            <input
              className="Form-field"
              type="text"
              name="presenter"
              onChange={e => {
                setPresenter(e.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <strong>&nbsp;</strong>
            <input
              onClick={SearchPresentations}
              className="Form-button"
              type="button"
              value="Search"
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            <label className="Box-title Box--green">Summary</label>
            <Box>
              <DataTable
                columns={columns}
                data={tableData}
                customStyles={customStyles}
              />
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default OwnerDashboard;
