import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "./auth.service";
import { io } from "socket.io-client";
//import { Console } from "console";

const SocketContext = createContext();
var socket;

const SocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const { REACT_APP_BACKEND_SERVER_URL } = process.env;
  const userinfo = AuthService.currentUserValue;

  const [notifications, setNotifications] = useState({});
  const [Displayed, OsetDisplayed] = useState([]);
  const [Timeline, SetTimeline] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [user, setUser] = useState();

  const history = useHistory();

  useEffect(() => {
    //  if (!socketConnected)
    {
      socket = io(`${REACT_APP_BACKEND_SERVER_URL}`, {
        transports: ["websocket"]
      });
      //socket.emit("setup", userinfo);
      socket.on("connected", "connected to server");
    }
    return () => {};
  }, []);
  useEffect(() => {
    let item = localStorage.getItem("notifications");
    if (item) {
      const timelineInfo = JSON.parse(item);
      SetTimeline(timelineInfo);
      // console.log(timelineInfo,Timeline);
      // if(timelineInfo)
      // {
      //   //timelineInfo.push(input);
    }
  }, [history]);

  useEffect(() => {
    //  if (!socketConnected)
    {
      let item = localStorage.getItem("notifications");
      localStorage.setItem("notifications", JSON.stringify(Timeline));

      if (item) {
        // const timelineInfo = JSON.parse(localStorage.getItem("notifications"));
        // console.log(timelineInfo,Timeline);
        // if(timelineInfo)
        // {
        //   //timelineInfo.push(input);
        // }
      }
    }
    return () => {};
  }, [Timeline]);
  const connectsocket = () => {
    console.log("setting socket");
    if (!socket) {
      socket = io(`${REACT_APP_BACKEND_SERVER_URL}`, {
        transports: ["websocket"]
      });
      // socket.emit("setup", userinfo);
      socket.on("connected", "connected to server");
    }
  };

  const getTimeline = () => {
    const user = AuthService.currentUserValue;

    // const timelineInfo = JSON.parse(localStorage.getItem("notifications"));
    if (user) {
      console.log(user.timeline);
      SetTimeline(user.timeline);
    } else {
      SetTimeline([]);
    }
  };

  const addToTimeline = data => {
    // console.log("i m in add timeline",data)
    // const timelineInfo = JSON.parse(localStorage.getItem("notifications"));
    // if (timelineInfo) {
    //   SetTimeline([data, ...timelineInfo]);
    // } else {
    //   SetTimeline([]);
    // }
    // try {
    //   localStorage.setItem("notifications", JSON.stringify(Timeline));
    // } catch (e) {
    //   console.error(e);
    // }
  };

  return (
    <SocketContext.Provider
      value={{
        // user,
        // setUser,addNotification
        notifications,
        setNotifications,
        addToTimeline,
        Displayed,
        OsetDisplayed,
        Timeline,
        SetTimeline,
        messages,
        setMessages,
        socket,
        socketConnected,
        connectsocket,
        getTimeline
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(SocketContext);
};

export default SocketProvider;

// import socketio from "socket.io-client";
// const { REACT_APP_BACKEND_SERVER_URL } = process.env;

// export const socket = socketio.connect(REACT_APP_BACKEND_SERVER_URL);
// export const SocketContext = React.createContext();
