import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const Privacy = props => {
  const location = useLocation();
  const history = useHistory();
  return (
    <div>
      <form>
        <h3>Privacy Policy</h3>
        <h3>What Information we collect?</h3>
        <p>
          We only collect your Full name, Email, phone number and Address for
          user registeration, the info is madatory for user sign ups.
        </p>
        <h3>How do we use the information collected?</h3>
        <p>
          We use the above said information to notify you about new
          presentations and verification
        </p>
        <h3>What information do we share?</h3>
        <p>We do not share any informationwith any third party</p>
      </form>
    </div>
  );
};

export default Privacy;
