import React, { useState, useEffect } from "react";
import Axios from "axios";
import { PropTypes } from "prop-types";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { Redirect, useHistory } from "react-router-dom";
import * as constantsClass from "../../utils/constants";
import AuthService from "../../services/auth.service";

import "dotenv/config";
const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const Users = props => {
  let history = useHistory();

  const user = AuthService.currentUserValue;
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };
  const [data, setdata] = useState("");
  const [presentationLink, setPresentationLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  
  const getVideoComposition = async (p_composition_sid) => {
    try {
      var view_url = "";
      
      const response_view = await Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Twilio/Composition/View`,
        {
          p_composition_sid: p_composition_sid
        },
        {
          headers: { "x-access-token": user.accessToken }
        }
      );
      if (response_view.data.success) {
        window.location.replace(response_view.data.video_url);
      }
      
    } catch (error) {
      console.error(error.message);
    }
  };
  const getDataset = () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/searchPresentations`,
        {
          p_returnstats: false,
          p_returndataset: true,
          p_presentation_status: selectedOption,
          p_seller_id: user.user_id,
          p_dealerName: "",
          p_presenter_id: "",
          p_presenter_name: "",
          p_startDate: "",
          p_endDate: "",
          p_stockno: ""
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(
        response => {
          console.log(response);
          setdata(response.data.results[0]);
          //console.log(temp);
        },
        error => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(_content);
          //setContent(_content);

          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            AuthService.logout();
            history.push("/login");
            //EventBus.dispatch("logout");
          }
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log(selectedOption);
      getDataset();
      setLoading(false);
    };

    fetchData();
  }, [selectedOption]);

  const columns = [
    {
      name: "Customer Name",
      selector: row => row.customer_name
    },
    {
      name: "Stock #",
      selector: row => row.stockNo
    },
    {
      name: "Year",
      selector: row => row.year
    },
    {
      name: "Make",
      selector: row => row.make
    },
    {
      name: "Model",
      selector: row => row.model
    },
    {
      name: "Mileage",
      selector: row => row.miles
    },
    {
      name: "Cost",
      selector: row => row.cost
    },
    {
      name: "Profit",
      selector: row => row.profit
    },
    {
      name: "Date",
      selector: row => row.order_date
    },
    {
      name: "Status",
      selector: row => row.status_name
    },
    {
      name: "Action",
      cell: row => (
        <div>
        <input
          className="Form-button"
          type="button"
          value="Join"
          hidden = {row.status_name == "Completed" ? true : false}
          onClick={e=>{
            var path = `/Presentation/${row.presentation_link}/${user.username}`;
            history.push({
              pathname: path,
              state: {
                p_order_id: row.order_id,
                p_user_role: user.roles
              }
            })
          }}
        />
        <input
          className="Form-button"
          type="button"
          value="View Video"
          hidden = {row.composition_view_url === "" || row.composition_view_url===null ? true : false}
          onClick={e=>{
            getVideoComposition(row.composition_sid)
          }}
        />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];
  const handleChange = e => {
    setSelectedOption(e.target.value);
  };
  return (
    <div>
      <Box>
        <div className="row">
          <div className="col-md-8">
            <h3>All Presentation</h3>
          </div>
          <div className="col-md-4">
            Presentation Status:{" "}
            <select onChange={e => handleChange(e)}>
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="In-Progress">In-Progress</option>
            </select>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </Box>
    </div>
  );
};

export default Users;
