import React from "react";
import { PropTypes } from "prop-types";
import MenuItem from "./components/MenuItem";

import "./menu.scss";

const Menu = props => {
  const { caption, items, display } = props;

  return (
    <div>
      {display && caption && <h3 className="Menu-caption">{caption}</h3>}
      {display && (
        <ul className="Menu">
          {items.map(item => (
            <MenuItem key={Math.random()} item={item} />
          ))}
        </ul>
      )}
    </div>
  );
};

Menu.propTypes = {
  caption: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default Menu;
