import Axios from "axios";

import { PropTypes } from "prop-types";
import SummaryBoxRow from "./SummaryBoxRow";
import Box from "./../../bootstrap_components/Box";
import { Chart } from "react-google-charts";
import { Redirect, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import authHeader from "../../services/auth-header";
import handleResponse from "../../services/handle-response";

import * as constantsClass from "../../utils/constants";
import "dotenv/config";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const DashboardSeller = props => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [presentations, setpresentations] = useState("0");
  const [completed, setcompleted] = useState("0");
  const [pending, setpending] = useState("0");
  const [netProfit, setnetProfit] = useState("0");
  const [chart, setChartArray] = useState([]);

  const user = AuthService.currentUserValue;
  const getStats = () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/searchPresentations`,
        {
          p_returnstats: true,
          p_returndataset: false,
          p_presentation_status: "",
          p_seller_id: user.user_id,
          p_dealerName: "",
          p_presenter_id: "",
          p_presenter_name: "",
          p_startDate: "",
          p_endDate: "",
          p_stockno: ""
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(
        response => {
          console.log(response);
          setpresentations(response.data.results[1][0].totalPresentations);
          setcompleted(response.data.results[1][0].completed);
          setpending(response.data.results[1][0].Pending);
          setnetProfit(response.data.results[1][0].profit);
          let temp = [];
          temp.push(["Stock", "Count", { role: "style" }]);
          response.data.results[0].forEach(item =>
            temp.push([item.stock, item.count, ""])
          );
          setChartArray(temp);
          //console.log(temp);
        },
        error => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(_content);
          //setContent(_content);

          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            AuthService.logout();
            history.push("/login");
            //EventBus.dispatch("logout");
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      getStats();
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <LoadingOverlay active={loading} spinner={<BounceLoader />}>
      <div>
        <SummaryBoxRow
          presentations={presentations}
          completed={completed}
          pending={pending}
          netProfit={netProfit}
        />

        <div className="row">
          <div className="col-md-12">
            <Box className="ChartBox">
              <h2 className="ChartBox-title">Products Sold</h2>

              <Chart
                options={{
                  chartArea: { width: "100%", height: "75%" },
                  legend: { position: "bottom" },
                  colors: [
                    "#12c5d6",
                    "#40d47e",
                    "#ec8f6e",
                    "#ea6153",
                    "#f6c7b6"
                  ]
                }}
                chartType="PieChart"
                data={chart}
                width={"100%"}
                height={"300px"}
              />
            </Box>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

function getPieChartData() {
  return [
    ["Goods", "Amount of sold"],
    ["Yatch", 210],
    ["Cars", 50],
    ["Bus", 36],
    ["Bikes", 135],
    ["Boats", 8]
  ];
}

export default DashboardSeller;
