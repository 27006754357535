import "../../App.css";
import React, { FC, useState, useEffect } from "react";
import { styled, Theme } from "@material-ui/core/styles";
import Header from "../../bootstrap_components/Header";
import SideNav from "../../bootstrap_components/SideNav";
import { io, Socket } from "socket.io-client";
import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import { owner, presenter, seller } from "../../utils/constants";
import { IconButton, Spinner, useToast } from "@chakra-ui/react";
import { ChatState } from "../../services/socket";
import { DefaultEventsMap } from "@socket.io/component-emitter";

export const Master: FC<{}> = ({ children }) => {
  let history = useHistory();

  const { REACT_APP_BACKEND_SERVER_URL } = process.env;
  const pageTitle = "VIRTUAL DEALER MANAGEMENT";
  const user = AuthService.currentUserValue;

  const {
    // user,
    //setUser,
    notifications,
    setNotifications,
    messages,
    setMessages,
    socket,
    Displayed,
    OsetDisplayed,
    connectsocket,
    getTimeline
  } = ChatState();

  useEffect(() => {
    console.log(
      "in meesage useeffect: for all messages recieved add in notification list"
    );
    messages.map((n: any) => {
      if (n.roles == user.roles) {
        console.log("recievd n from messages in message use eeffect", n);
        setNotifications(n);
      }
    });
    return () => {
      setNotifications([]); // This worked for me
    };
  }, [messages]);

  //whenever new messsage is recieved,add it into messages array irrespective for whom it concerns
  useEffect(() => {
    if (socket != null) {
      socket.on("message", (data: any) => {
        //if (data.roles == user.roles)
        //console.log(socket,data);
        {
          if (messages.some((e: { mid: any }) => e.mid === data.mid)) {
            console.log("already in message array");
          } else {
            setMessages((prev: any) => [...prev, data]);
          }
        }
      });
    } else {
      connectsocket();
    }
    return () => {
      setMessages([]); // This worked for me
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <SideNav />
        <div className="col-md-2"></div>
        <div className="col-md-10 pull-right">
          <Header title={pageTitle} username={user.username} />

          <main className="Content">{children}</main>
        </div>
      </div>
    </div>
  );
};
