import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { Chart } from "react-google-charts";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Axios from "axios";
import { subDays } from "date-fns";
import * as constantsClass from "../../utils/constants";
import "dotenv/config";
import moment from "moment";

import AuthService from "../../services/auth.service";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const OwnReport = props => {
  const [Loading, SetLoading] = useState(true);
  const [Revevnue, SetRevenue] = useState("0");
  const [ActiveSellers, SetActiveSellers] = useState("0");
  const [ActivePresenters, SetActivePresenters] = useState("0");
  const [GrossProfit, SetgrossProfit] = useState("0");
  const [Commission, SetCommission] = useState("0");
  const [NetProfit, SetNetProfit] = useState("0");

  const [Value, OnChange] = useState([subDays(new Date(), 30), new Date()]);
  const [tableData, settableData] = useState([]);
  const [currentMonth, setcurrentMoth] = useState(moment().format("MMMM-YYYY"));

  const user = AuthService.currentUserValue;
  //console.log(user);
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };
  const divStyle = {
    marginRight: "20px",
    marginLeft: "20px"
  };
  const data = [
    ["Year", "Sales", "Expenses"],
    ["2004", 1000, 400],
    ["2005", 1170, 460],
    ["2006", 660, 1120],
    ["2007", 1030, 540]
  ];
  const options = {
    //title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" }
  };

  const SearchPresentations = () => {
    GetStats(
      Value[0].toISOString().split("T")[0],
      Value[1].toISOString().split("T")[0]
    );
  };
  const selectionRange = {
    startDate: subDays(new Date(), 90),
    endDate: new Date(),
    key: "selection"
  };
  const handleSelect = date => {
    console.log(date); // native Date object
  };

  const GetStats = (pSDate, pEDate) => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/ownerPresentation`,
        {
          p_startDate: pSDate,
          p_endDate: pEDate
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(response => {
        console.log(response);
        SetActivePresenters(response.data.results[0][0].active_presenters);
        SetActiveSellers(response.data.results[0][0].active_dealers);
        SetRevenue(response.data.results[0][0].revenue);
        SetgrossProfit(response.data.results[1][0].gross_profit);
        SetCommission(response.data.results[1][0].presenter_commision);
        SetNetProfit(response.data.results[1][0].net_profit);
        let temp = [];
        temp.push([
          "Date",
          "Revenue",
          "Gross Profit",
          "Commission",
          "Net Profit",
          { role: "style" }
        ]);
        response.data.results[2].forEach(item =>
          temp.push([
            item.dte,
            item.profit,
            item.gross_profit,
            item.presenter_commission,
            item.net_profit,
            ""
          ])
        );
        settableData(temp);
        console.log(temp);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const FetchData = async () => {
      SetLoading(true);
      GetStats(
        Value[0].toISOString().split("T")[0],
        Value[1].toISOString().split("T")[0]
      );
      SetLoading(false);
    };

    FetchData();
  }, []);

  return (
    <>
      <Box>
        <div className="row">
          <div className="col-md-3">
            <strong>Date Range</strong>
            <DateRangePicker onChange={OnChange} value={Value} />
          </div>

          <div className="col-md-3">
            <strong>&nbsp;</strong>
            <input
              onClick={SearchPresentations}
              className="Form-button"
              type="button"
              value="Search"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="Box-title Box--orange">
              Platform Overview - ({currentMonth})
            </label>
            <Box>
              <div className="row">
                <div className="col-md-3">
                  <Box className="SummaryBox Box--red BoxSpace">
                    <h2 className="SummaryBox-title">Total Revenue</h2>

                    <strong className="SummaryBox-value">
                      {Revevnue || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--blue BoxSpace">
                    <h2 className="SummaryBox-title">Active Sellers</h2>

                    <strong className="SummaryBox-value">
                      {ActiveSellers || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--green BoxSpace">
                    <h2 className="SummaryBox-title">Active Presenters</h2>

                    <strong className="SummaryBox-value">
                      {ActivePresenters || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
              </div>
            </Box>

            <Box>
              <div className="row">
                <div className="col-md-3">
                  <Box className="SummaryBox Box--yellow BoxSpace">
                    <h2 className="SummaryBox-title">Gross Profit</h2>

                    <strong className="SummaryBox-value">
                      {GrossProfit || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--purple BoxSpace">
                    <h2 className="SummaryBox-title">Commission</h2>

                    <strong className="SummaryBox-value">
                      {Commission || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
                <div className="col-md-3">
                  <Box className="SummaryBox Box--pink BoxSpace">
                    <h2 className="SummaryBox-title">Net Profit</h2>

                    <strong className="SummaryBox-value">
                      {NetProfit || 0}
                    </strong>

                    <i className="SummaryBox-icon fa fa-list" />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label className="Box-title Box--green">Platform Performance</label>
            <Box>
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={tableData}
                options={options}
              />
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default OwnReport;
