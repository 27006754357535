import React, { useState, useEffect } from "react";
import {useParams } from "react-router-dom";
import Axios from "axios";
import "dotenv/config";
const { 
  REACT_APP_BACKEND_SERVER_URL} = process.env

const Verify = props => {
  const { VerificationCode } = useParams();
  const [message, setMessage] = useState();
  const verifyEmail = async () => {
    try {
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Users/verifyEmail`, {
        p_email_verification_code: VerificationCode
      }).then(response => {
        setMessage(response.data.message)
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    verifyEmail();
    return () => {};
  }, []);
  return (
    <div>
    <form>
        <p>{message}</p>
    </form> 
    
    </div>   
  );
};

export default Verify;
