import React, { useState, useEffect } from "react";
import Axios from "axios";
import Box from "../../bootstrap_components/Box";
import { useHistory } from "react-router-dom";
import * as constantsClass from "../../utils/constants";
import "dotenv/config";
import AuthService from "../../services/auth.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "@material-ui/core";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const submitRe = () => {
  console.log("here");
  Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Users/Authenticate`, {
    username: "test",
    password: "test"
  }).then(response => {
    console.log(response);
  });
};

const CreatePresentation = props => {
  var pmt = require("formula-pmt");
  const user = AuthService.currentUserValue;

  let history = useHistory();
  const [total, set_total] = useState(0);
  const [monthlyPayment, set_monthlyPayment] = useState(0);
  const [stockNumber, setStockNumber] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [miles, setMiles] = useState("");
  const [paymentFinanced, setPaymentFinanced] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhoneNummber, setCustomerPhoneNummber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerCity, setcustomerCity] = useState("");
  const [customerZip, setcustomerZip] = useState("");
  const [pmtRate, setAPRrate] = useState(0);
  const [values, set_values] = useState({
    sellingPrice: "0",
    apr: "0",
    tax: "0",
    termsInMonths: "1",
    docFees: "0",
    plateFees: "0",
    titleFees: "0",
    downPayment: "0"
  });

  const value_handler = e => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = {
      ...values,
      [name]: value
    };
    set_values(newValues);

    // Calling the method to sum the value
    calc_total(newValues);
  };

  const calc_total = newValues => {
    const {
      sellingPrice,
      apr,
      tax,
      termsInMonths,
      docFees,
      plateFees,
      titleFees,
      downPayment
    } = newValues;

    if (
      parseInt(apr) != 0 &&
      parseInt(termsInMonths) != 1 &&
      parseInt(paymentFinanced) != 0
    ) {
      console.log(
        parseInt(apr) / 100 / 12,
        termsInMonths,
        paymentFinanced,
        pmt(
          parseInt(apr) / 100 / 12,
          parseInt(termsInMonths),
          parseInt(paymentFinanced),
          0,
          0
        )
      );
      setAPRrate(
        -1 *
          pmt(
            parseInt(apr) / 100 / 12,
            parseInt(termsInMonths),
            parseInt(paymentFinanced)
          )
      );
    }

    const newTotal =
      parseInt(sellingPrice) +
      //parseInt(apr) +
      parseInt(tax) +
      parseInt(docFees) +
      parseInt(plateFees) +
      parseInt(titleFees) -
      downPayment;
    set_monthlyPayment(newTotal / termsInMonths);
    set_total(newTotal);
    setPaymentFinanced(newTotal);
  };

  const onChangeHandler = async event => {
    setPaymentFinanced(
      parseInt(event.target.value == 0 ? total : event.target.value)
    );
  };
  useEffect(() => {
    //   setAPRrate(pmt( (parseInt(values.apr)/100) / 12, values.termsInMonths, paymentFinanced )
    // )

    if (
      parseInt(values.apr) != 0 &&
      parseInt(values.termsInMonths) != 1 &&
      parseInt(paymentFinanced) != 0
    ) {
      set_monthlyPayment(
        paymentFinanced == 0
          ? total / values.termsInMonths
          : paymentFinanced / values.termsInMonths
      );

      //console.log((parseInt(values.apr)/100) / 12, values.termsInMonths, paymentFinanced, pmt( (parseInt(values.apr)/100) / 12, parseInt(values.termsInMonths), parseInt(paymentFinanced) , 0,  0))
      setAPRrate(
        -1 *
          pmt(
            parseInt(values.apr) / 100 / 12,
            parseInt(values.termsInMonths),
            parseInt(paymentFinanced)
          )
      );
    }
  });

  const CreateNewPresentation = async () => {
    console.log("here");

    Axios.post(
      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Create`,
      {
        p_seller_id: user.user_id, //current seller userid
        p_stockNumber: stockNumber,
        p_year: year,
        p_make: make,
        p_model: model,
        p_miles: miles,
        p_sellingPrice: values.sellingPrice,
        p_apr: values.apr,
        p_pmt: pmtRate,
        p_tax: values.tax,
        p_termsInMonths: values.termsInMonths,
        p_docFees: values.docFees,
        p_plateFees: values.plateFees,
        p_titleFees: values.titleFees,
        p_downPayment: values.downPayment,
        p_actualCost:
          parseFloat(values.sellingPrice) +
          parseFloat(values.tax) +
          parseFloat(values.docFees) +
          parseFloat(values.plateFees) +
          parseFloat(values.titleFees),
        p_totalPrice: total,
        p_amountFInanced: paymentFinanced,
        p_monthlyPayment: parseFloat(monthlyPayment),
        p_monthlyPaymentwithAPR:
          (parseFloat(pmtRate) + parseFloat(paymentFinanced)) /
          parseFloat(values.termsInMonths),
        p_presentation_link: "someuid",
        p_customerName: customerName,
        p_customerEmail: customerEmail,
        p_customerPhoneNummber: customerPhoneNummber,
        p_customerAddress: customerAddress,
        p_customerState: customerState,
        p_customerCountry: customerCountry,
        p_customercity: customerCity,
        p_customerzip: customerZip
      },
      { headers: { "x-access-token": user.accessToken } }
    ).then(
      responseCreatePresentation => {
        console.log(responseCreatePresentation);
        if (responseCreatePresentation.data.success) {
          let path = `/Presentation/${responseCreatePresentation.data.room_name}/${user.username}`;
          history.push({
            pathname: path,

            state: {
              p_order_id: responseCreatePresentation.data.p_order_id,
              p_user_role: user.roles
            }
          });
        } else {
          alert(responseCreatePresentation.data.message);
        }

        //console.log(temp);
      },
      error => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(_content);
        //setContent(_content);

        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status === 403)
        ) {
          AuthService.logout();
          history.push("/login");
          //EventBus.dispatch("logout");
        }
      }
    );

    // const responseCreatePresentation = await Axios.post(
    //   `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Create`,
    //   {
    //     p_seller_id: user.user_id, //current seller userid
    //     p_stockNumber: stockNumber,
    //     p_year: year,
    //     p_make: make,
    //     p_model: model,
    //     p_miles: miles,
    //     p_sellingPrice: values.sellingPrice,
    //     p_apr: values.apr,
    //     p_pmt: pmtRate,
    //     p_tax: values.tax,
    //     p_termsInMonths: values.termsInMonths,
    //     p_docFees: values.docFees,
    //     p_plateFees: values.plateFees,
    //     p_titleFees: values.titleFees,
    //     p_downPayment: values.downPayment,
    //     p_actualCost:
    //       parseFloat(values.sellingPrice) +
    //       parseFloat(values.tax) +
    //       parseFloat(values.docFees) +
    //       parseFloat(values.plateFees) +
    //       parseFloat(values.titleFees),
    //     p_totalPrice: total,
    //     p_amountFInanced: paymentFinanced,
    //     p_monthlyPayment: parseFloat(monthlyPayment),
    //     p_monthlyPaymentwithAPR:
    //       (parseFloat(pmtRate) + parseFloat(paymentFinanced)) /
    //       parseFloat(values.termsInMonths),
    //     p_presentation_link: "someuid",
    //     p_customerName: customerName,
    //     p_customerEmail: customerEmail,
    //     p_customerPhoneNummber: customerPhoneNummber,
    //     p_customerAddress: customerAddress,
    //     p_customerState: customerState,
    //     p_customerCountry: customerCountry
    //   }
    // );
    // if (responseCreatePresentation.data.success) {
    //   let path = `/Presentation/${responseCreatePresentation.data.room_name}/${user.username}`;
    //   history.push({
    //     pathname: path,

    //     state: {
    //       p_order_id: responseCreatePresentation.data.p_order_id,
    //       p_user_role: "Company"
    //     }
    //   });
    // } else {
    //   alert(responseCreatePresentation.data.message);
    // }
  };

  return (
    <div>
      <Formik
        initialValues={{
          stockNumber: "",
          year: "",
          make: "",
          model: "",
          miles: "",
          sellingPrice: "0",
          tax: "0",
          docFees: "0",
          plateFees: "0",
          titleFees: "0",
          termsInMonths: "0",
          downPayment: "0",
          apr: "0",
          customerName: "",
          customerEmail: "",
          customerPhoneNummber: "",
          customerAddress: "",
          customerState: "",
          customerCountry: ""
        }}
        validationSchema={Yup.object().shape({
          stockNumber: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Required")
            .matches(
              /^[a-zA-Z0-9.#/ -]*$/,
              "Can only contain letters, digits, spaces, #, /, - and ."
            ),
          year: Yup.string()
            .min(1, "Too Short!")
            .max(4, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          make: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Required")
            .matches(
              /^[a-zA-Z0-9.#/ -]*$/,
              "Can only contain letters, digits, spaces, #, /, - and ."
            ),
          model: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Required")
            .matches(
              /^[a-zA-Z0-9.#/ -]*$/,
              "Can only contain letters, digits, spaces, #, /, - and ."
            ),
          miles: Yup.string()
            .min(1, "Too Short!")
            .max(6, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          sellingPrice: Yup.string()
            .min(2, "Too Short!")
            .max(8, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          tax: Yup.string()
            .min(1, "Too Short!")
            .max(6, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          docFees: Yup.string()
            .min(1, "Too Short!")
            .max(6, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          plateFees: Yup.string()
            .min(1, "Too Short!")
            .max(6, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          titleFees: Yup.string()
            .min(1, "Too Short!")
            .max(6, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          termsInMonths: Yup.string()
            .min(1, "Too Short!")
            .max(4, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          apr: Yup.string()
            .min(1, "Too Short!")
            .max(2, "Too Long!")
            .required("Required")
            .matches(/^[0-9]*$/, "Can only contain digits"),
          customerName: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Required")
            .matches(
              /^[a-zA-Z0-9.#/ -]*$/,
              "Can only contain letters, digits, spaces, #, /, - and ."
            ),
          customerEmail: Yup.string()
            .max(150, "Too Long!")
            .required("Required")
            .email("Invalid Email"),
          customerPhoneNummber: Yup.string()
            .min(6, "Too Short!")
            .max(20, "Too Long!")
            .required("Required")
            .matches(/^[0-9+-]*$/, "Can only contain digits, +, and -"),
          customerAddress: Yup.string()
            .required("Required")
            .min(6, "Too Short!")
            .max(250, "Too Long!")
            .matches(
              /^[a-zA-Z0-9.#/ -]*$/,
              "Can only contain letters, digits, spaces, #, /, - and ."
            ),
          customerState: Yup.string().matches(
            /^[a-zA-Z0-9.#/ -]*$/,
            "Can only contain letters, digits, spaces, #, /, - and ."
          ),
          customerCountry: Yup.string().matches(
            /^[a-zA-Z0-9.#/ -]*$/,
            "Can only contain letters, digits, spaces, #, /, - and ."
          ),
          customerCity: Yup.string().matches(
            /^[a-zA-Z0-9.#/ -]*$/,
            "Can only contain letters, digits, spaces, #, /, - and ."
          ),
          customerZip: Yup.string().matches(
            /^[0-9+-]*$/,
            "Can only contain digits, +, and -"
          )
        })}
        onSubmit={({}, { setStatus, setSubmitting }) => {
          setStatus();
          CreateNewPresentation();
          setSubmitting(false);
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form>
            <Box>
              <div className="row">
                <div className="row">
                  <div className="col-md-10">
                    <h2>Create Presentation</h2>
                  </div>
                  <div className="col-md-2">
                    <input
                      //onClick={CreateNewPresentation}
                      className="Form-button"
                      type="submit"
                      value="Save"
                      disabled={isSubmitting}
                    />
                    {isSubmitting && (
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )}
                    {status && (
                      <div className={"alert alert-danger"}>{status}</div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 ">
                    <label className="Box-title Box--orange">
                      Product Info
                    </label>
                    <Box>
                      <div className="row">
                        <div
                          className="col-md-10"
                          onChange={e => {
                            setStockNumber(e.target.value);
                          }}
                        >
                          <strong>Stock #</strong>
                          <Field
                            type="text"
                            name="stockNumber"
                            className={
                              "Form-field" +
                              (errors.stockNumber && touched.stockNumber
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="stockNumber"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div
                          className="col-md-10"
                          onChange={e => {
                            setYear(e.target.value);
                          }}
                        >
                          <strong>Year</strong>
                          <Field
                            type="text"
                            name="year"
                            className={
                              "Form-field" +
                              (errors.year && touched.year ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="year"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div
                          className="col-md-10"
                          onChange={e => {
                            setMake(e.target.value);
                          }}
                        >
                          <strong>Make</strong>
                          <Field
                            type="text"
                            name="make"
                            className={
                              "Form-field" +
                              (errors.make && touched.make ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="make"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div
                          className="col-md-10"
                          onChange={e => {
                            setModel(e.target.value);
                          }}
                        >
                          <strong>Model</strong>
                          <Field
                            type="text"
                            name="model"
                            className={
                              "Form-field" +
                              (errors.model && touched.model
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="model"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div
                          className="col-md-10"
                          onChange={e => {
                            setMiles(e.target.value);
                          }}
                        >
                          <strong>Miles</strong>
                          <Field
                            type="text"
                            name="miles"
                            className={
                              "Form-field" +
                              (errors.miles && touched.miles
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="miles"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-10" onChange={value_handler}>
                          <strong>Selling Price</strong>
                          <div class="input-icon">
                            <Field
                              type="text"
                              name="sellingPrice"
                              className={
                                "Form-field" +
                                (errors.sellingPrice && touched.sellingPrice
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="sellingPrice"
                              component="div"
                              className="invalid-feedback"
                            />
                            <i>$</i>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                  <div className="col-md-8">
                    <label className="Box-title Box--red">Tax Info</label>
                    <Box>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Tax</strong>
                            <div class="input-icon">
                              <Field
                                type="text"
                                name="tax"
                                className={
                                  "Form-field" +
                                  (errors.tax && touched.tax
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="tax"
                                component="div"
                                className="invalid-feedback"
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Doc Fees</strong>
                            <div class="input-icon">
                              <Field
                                type="text"
                                name="docFees"
                                className={
                                  "Form-field" +
                                  (errors.docFees && touched.docFees
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="docFees"
                                component="div"
                                className="invalid-feedback"
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Plate Fees</strong>
                            <div class="input-icon">
                              <Field
                                type="text"
                                name="plateFees"
                                className={
                                  "Form-field" +
                                  (errors.plateFees && touched.plateFees
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="plateFees"
                                component="div"
                                className="invalid-feedback"
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Title Fees</strong>
                            <div class="input-icon">
                              <Field
                                type="text"
                                name="titleFees"
                                className={
                                  "Form-field" +
                                  (errors.titleFees && touched.titleFees
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="titleFees"
                                component="div"
                                className="invalid-feedback"
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Terms (in Months)</strong>
                            <Field
                              type="text"
                              name="termsInMonths"
                              className={
                                "Form-field" +
                                (errors.termsInMonths && touched.termsInMonths
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="termsInMonths"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>Down Payment</strong>
                            <div class="input-icon">
                              <Field
                                type="text"
                                name="downPayment"
                                className={
                                  "Form-field" +
                                  (errors.downPayment && touched.downPayment
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="downPayment"
                                component="div"
                                className="invalid-feedback"
                              />
                              <i>$</i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="col-md-10">
                            <strong>Total Payment</strong>
                            <div class="input-icon">
                              <input
                                className="Form-field"
                                style={{ color: "black" }}
                                type="text"
                                value={total}
                                name="Payment"
                                disabled
                              />
                              <i>$</i>
                            </div>
                          </div>

                          <div className="col-md-10">
                            <strong>Payment Financed</strong>
                            <div class="input-icon">
                              <input
                                className="Form-field"
                                type="text"
                                value={paymentFinanced}
                                name="paymentFinanaced"
                                onChange={event =>
                                  setPaymentFinanced(
                                    parseInt(
                                      event.target.value == 0
                                        ? total
                                        : event.target.value
                                    )
                                  )
                                }
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10" onChange={value_handler}>
                            <strong>APR (%age) </strong>
                            <Field
                              type="number"
                              name="apr"
                              className={
                                "Form-field" +
                                (errors.apr && touched.apr ? " is-invalid" : "")
                              }
                            />
                            <ErrorMessage
                              name="apr"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-md-10">
                            <strong>Monthly Payment w/o APR</strong>
                            <div class="input-icon">
                              <input
                                className="Form-field"
                                style={{ color: "black" }}
                                type="number"
                                step="0.01"
                                value={monthlyPayment}
                                name="monthly"
                                disabled
                              />
                              <i>$</i>
                            </div>
                          </div>

                          <div className="col-md-10">
                            <strong>pmt Payment</strong>
                            <div class="input-icon">
                              <input
                                className="Form-field"
                                style={{ color: "black" }}
                                type="number"
                                step="0.01"
                                value={pmtRate}
                                name="pmtrate1"
                                disabled
                              />
                              <i>$</i>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <strong>Monthly Payment with APR</strong>
                            <div class="input-icon">
                              <input
                                className="Form-field"
                                style={{ color: "black" }}
                                type="text"
                                value={
                                  (parseInt(pmtRate) +
                                    parseInt(paymentFinanced)) /
                                  parseInt(values.termsInMonths)
                                }
                                name="monthlywithpmr"
                                disabled
                              />
                              <i>$</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>

                <label className="Box-title Box--blue">Customer Info</label>
                <Box>
                  <div className="row">
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setCustomerName(e.target.value);
                      }}
                    >
                      <strong>Customer Name</strong>
                      <Field
                        type="text"
                        name="customerName"
                        className={
                          "Form-field" +
                          (errors.customerName && touched.customerName
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setCustomerEmail(e.target.value);
                      }}
                    >
                      <strong>Email</strong>
                      <Field
                        type="emil"
                        name="customerEmail"
                        className={
                          "Form-field" +
                          (errors.customerEmail && touched.customerEmail
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerEmail"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setCustomerPhoneNummber(e.target.value);
                      }}
                    >
                      <strong>Phone Number</strong>
                      <Field
                        type="text"
                        name="customerPhoneNummber"
                        className={
                          "Form-field" +
                          (errors.customerPhoneNummber &&
                          touched.customerPhoneNummber
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerPhoneNummber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div
                      className="col-md-8"
                      onChange={e => {
                        setCustomerAddress(e.target.value);
                      }}
                    >
                      <strong>Address</strong>
                      <Field
                        type="text"
                        name="customerAddress"
                        className={
                          "Form-field" +
                          (errors.customerAddress && touched.customerAddress
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerAddress"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setcustomerCity(e.target.value);
                      }}
                    >
                      <strong>City</strong>
                      <Field
                        type="text"
                        name="customerCity"
                        className={
                          "Form-field" +
                          (errors.customerCity && touched.customerCity
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerCity"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setcustomerZip(e.target.value);
                      }}
                    >
                      <strong>Zip Code</strong>
                      <Field
                        type="text"
                        name="customerZip"
                        className={
                          "Form-field" +
                          (errors.customerZip && touched.customerZip
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerZip"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setCustomerState(e.target.value);
                      }}
                    >
                      <strong>State</strong>
                      <Field
                        type="text"
                        name="customerState"
                        className={
                          "Form-field" +
                          (errors.customerState && touched.customerState
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerState"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div
                      className="col-md-4"
                      onChange={e => {
                        setCustomerCountry(e.target.value);
                      }}
                    >
                      <strong>Country</strong>
                      <Field
                        type="text"
                        name="customerCountry"
                        className={
                          "Form-field" +
                          (errors.customerCountry && touched.customerCountry
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="customerCountry"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </Box>
          </Form>
        )}
      />
    </div>
  );
};

export default CreatePresentation;
