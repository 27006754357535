import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import "dotenv/config";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const ResetPassword = props => {
  const [isValid, setIsValid] = useState(true);
  const [hideForm, setHideForm] = useState(true);
  const { RecoveryCode } = useParams();
  const [message, setMessage] = useState();
  const VerifyRecoveryCode = async () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Users/VerifyRecoveryCode`,
        {
          p_password_recovery_code: RecoveryCode
        }
      ).then(response => {
        if (!response.data.success) {
          setMessage(response.data.message);
          setHideForm(true);
          setIsValid(false);
        } else {
          setHideForm(false);
          setIsValid(true);
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    VerifyRecoveryCode();
    return () => {};
  }, []);
  return (
    <div>
      <form>
        <p hidden={isValid}>{message}</p>
      </form>
      <div hidden={hideForm}>
        <h3>Reset Password</h3>
        <Formik
          initialValues={{
            password: "",
            confirm_password: ""
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().string()
            .required("Required")
            .min(6, 'Too Short!')
            .max(20, 'Too Long!')
            .matches(
              /^[a-zA-Z0-9@]*$/,
              'Can only contain letters, digits and @'
            ),
            confirm_password: Yup.string()
            .required("Required")
            .when("password", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              )
            })
          })}
          onSubmit={(
            { password, confirm_password },
            { setStatus, setSubmitting }
          ) => {
              setStatus();
              Axios.post(
                `${REACT_APP_BACKEND_SERVER_URL}/api/Users/ResetPassword`,
                {
                  p_password_recovery_code: RecoveryCode,
                  p_password: password
                }
              ).then(response => {
                setSubmitting(false);
                setStatus(response.data.message);
              });
          }}
          render={({ errors, status, touched, isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  hidden={hideForm}
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm_password">Confirm Password</label>
                <Field
                  hidden={hideForm}
                  name="confirm_password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.confirm_password && touched.confirm_password
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Reset
                </button>
                {isSubmitting && (
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                )}
              </div>

              {status && <div className={"alert alert-danger"}>{status}</div>}
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
