import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import React from "react";
import ReactDOM from "react-dom";
import socketIOClient from "socket.io-client";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import NotFoundPage from "../src/bootstrap_components/NotFoundPage";
import VideoAppLink from "./VideoApp";
import AppStateProvider, { useAppState } from "./state";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import LoginPage from "./components/LoginPage/LoginPage";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ProtectedRoute from "../src/components/PrivateRoute/protectedRoute";
import SocketProvider from "../src/services/socket";

import theme from "./theme";
import "./types";
import { ChatProvider } from "./components/ChatProvider";
import { VideoProvider } from "./components/VideoProvider";
import { Master } from "./components/MasterTemplate";
import { LoginMaster } from "./components/LoginTemplete";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import Dashboard from "./Pages/DashboardSeller/Dashboard";

import DashboardOwner from "./Pages/OwnerDashboard/Dashboard";
import OwnReport from "./Pages/OwnerReport/ownerReport";
import Privacy from "./Pages/Privacy";
import CreatePresentation from "./Pages/CreatePresentation";
import AllPresentations from "./Pages/AllPresentations";
import ReportSeller from "./Pages/ReportSeller/Report";
import PresentationAvailable from "./Pages/PresentationAvailable";
import Presentation from "./Pages/Presentation";
import PresentationFinalization from "./Pages/PresentationFinalization";
import PendingPresentations from "./Pages/PendingPresentations";
import SendEmail from "./Pages/SendEmail";
import Login from "./Pages/Login/index";
import Register from "./Pages/Signup";
import Congratulations from "./Pages/Greetings";
import Verify from "./Pages/VerifyEmail";
import ActivateUser from "./Pages/ActivateUserProfile";
import BlockUsers from "./Pages/BlockUserProfile";
import { Role } from "../src/utils/user_roles";
import AuthService from "../src/services/auth.service";
import ForgotPassword from "./Pages/ForgotPass";
import ResetPassword from "./Pages/ResetPass";
const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <VideoAppLink />
      </ChatProvider>
    </VideoProvider>
  );
};
const PresentationApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <SocketProvider>
      <Master>
        <div className="col-md-9 video-conf">
          <VideoProvider options={connectionOptions} onError={setError}>
            <ErrorDialog dismissError={() => setError(null)} error={error} />
            <ChatProvider>
              <VideoAppLink />
            </ChatProvider>
          </VideoProvider>
        </div>
        <div className="col-md-3">
          <Presentation />
        </div>
      </Master>
    </SocketProvider>
  );
};
const DashboardApp = () => {
  return (
    <SocketProvider>
      <Master>
        <Dashboard />
      </Master>
    </SocketProvider>
  );
};
const OwnerReportApp = () => {
  return (
    <SocketProvider>
      <Master>
        <OwnReport />
      </Master>
    </SocketProvider>
  );
};
const DashboardOwnerApp = () => {
  return (
    <SocketProvider>
      <Master>
        <DashboardOwner />
      </Master>
    </SocketProvider>
  );
};
const SendEmailApp = () => {
  return (
    <SocketProvider>
      <Master>
        <SendEmail />
      </Master>
    </SocketProvider>
  );
};
const notFoundAPP = () => {
  return (
    <SocketProvider>
      <Master>
        <NotFoundPage />
      </Master>
    </SocketProvider>
  );
};

const CreatePresentationApp = () => {
  return (
    <SocketProvider>
      <Master>
        <CreatePresentation />
      </Master>
    </SocketProvider>
  );
};
const AllPresentationsApp = () => {
  return (
    <SocketProvider>
      <Master>
        <AllPresentations />
      </Master>
    </SocketProvider>
  );
};
const PendingPresentationsApp = () => {
  return (
    <SocketProvider>
      <Master>
        <PendingPresentations />
      </Master>
    </SocketProvider>
  );
};

const ReportSellerApp = () => {
  return (
    <SocketProvider>
      <Master>
        <ReportSeller />
      </Master>
    </SocketProvider>
  );
};

const PresentationAvailableApp = () => {
  return (
    <SocketProvider>
      <Master>
        <PresentationAvailable />
      </Master>
    </SocketProvider>
  );
};
const PresentationFinalizationApp = () => {
  return (
    <SocketProvider>
      <Master>
        <PresentationFinalization />
      </Master>
    </SocketProvider>
  );
};
const LoginApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <Login />
      </LoginMaster>
    </SocketProvider>
  );
};
const ForgotPasswordApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <ForgotPassword />
      </LoginMaster>
    </SocketProvider>
  );
};
const ResetPasswordApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <ResetPassword />
      </LoginMaster>
    </SocketProvider>
  );
};
const RegisterApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <Register />
      </LoginMaster>
    </SocketProvider>
  );
};
const GreetingsApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <Congratulations />
      </LoginMaster>
    </SocketProvider>
  );
};

const PrivacyAPP = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <Privacy />
      </LoginMaster>
    </SocketProvider>
  );
};

const VerifyApp = () => {
  return (
    <SocketProvider>
      <LoginMaster>
        <Verify />
      </LoginMaster>
    </SocketProvider>
  );
};
const ActivateUserApp = () => {
  return (
    <SocketProvider>
      <Master>
        <ActivateUser />
      </Master>
    </SocketProvider>
  );
};
const BlockUsersApp = () => {
  return (
    <SocketProvider>
      <Master>
        <BlockUsers />
      </Master>
    </SocketProvider>
  );
};
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route exact path="/">
              <LoginApp />
            </Route>
            {/* <Route exact path="/:token">
              <LoginApp />
            </Route> */}
            {/* <PrivateRoute exact path="/">
              <LoginApp />
            </PrivateRoute> */}
            <PrivateRoute exact path="/Login">
              <LoginApp />
            </PrivateRoute>
            <PrivateRoute exact path="/Login/:token">
              <LoginApp />
            </PrivateRoute>
            <PrivateRoute exact path="/ForgotPassword">
              <ForgotPasswordApp />
            </PrivateRoute>
            <PrivateRoute exact path="/ResetPassword/:RecoveryCode">
              <ResetPasswordApp />
            </PrivateRoute>
            <PrivateRoute exact path="/Register">
              <RegisterApp />
            </PrivateRoute>
            <PrivateRoute exact path="/Greetings">
              <GreetingsApp />
            </PrivateRoute>
            <PrivateRoute exact path="/Privacy">
              <PrivacyAPP />
            </PrivateRoute>
            <PrivateRoute exact path="/Verify/:VerificationCode">
              <VerifyApp />
            </PrivateRoute>
            <PrivateRoute path="/room/:URLRoomName/:URLRoomParticipent">
              <VideoApp />
            </PrivateRoute>
            <ProtectedRoute
              exact
              roles={[Role.Seller]}
              path="/Dashboard"
              component={DashboardApp}
            />{" "}
            <ProtectedRoute
              exact
              roles={[Role.Owner]}
              path="/OwnerDashboard"
              component={DashboardOwnerApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Owner]}
              path="/ownerReport"
              component={OwnerReportApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Owner]}
              path="/ActivateUser"
              component={ActivateUserApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Owner]}
              path="/BlockUser"
              component={BlockUsersApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Seller]}
              path="/CreatePresentation"
              component={CreatePresentationApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Seller]}
              path="/AllPresentations"
              component={AllPresentationsApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Seller]}
              path="/ReportSeller"
              component={ReportSellerApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Presenter]}
              path="/PresentationAvailable"
              component={PresentationAvailableApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Presenter]}
              path="/PresentationFinalization"
              component={PresentationFinalizationApp}
            />
            <ProtectedRoute
              exact
              roles={[Role.Presenter]}
              path="/PendingPresentations"
              component={PendingPresentationsApp}
            />
            <Route exact path="/SendEmail">
              <SendEmailApp />
            </Route>
            {/* <Route exact path="/CreatePresentation">
              <CreatePresentationApp />
            </Route>
            <Route exact path="/AllPresentations">
              <AllPresentationsApp />
            </Route>
            <Route exact path="/ReportSeller">
              <ReportSellerApp />
            </Route>
            <Route exact path="/PresentationAvailable">
              <PresentationAvailableApp />
            </Route> */}
            <Route exact path="/Presentation/:URLRoomName/:URLRoomParticipent">
              <PresentationApp />
            </Route>
            {/* <Route exact path="/PresentationFinalization">
              <PresentationFinalizationApp />
            </Route> */}
            {/* <Route path="/">
              <DashboardApp />
            </Route> */}
            {/* <Route path="/login">
              <DashboardApp />
            </Route> */}
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById("root")
);
