// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';

// import AuthService  from '../../services/user.service';

// const ProtectedRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => {
//         const currentUser = AuthService.getCurrentUser;
//         if (!currentUser) {
//             // not logged in so redirect to login page with the return url
//             return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
//         }

//         // authorised so return component
//         return <Component {...props} />
//     }} />
// )

// export default ProtectedRoute;
import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from "../../services/auth.service";
function ProtectedRoute({ component: Component, roles, ...restOfProps }) {
  return (
    // <Route
    //   {...restOfProps}
    //   render={(props) =>
    //     currentUser ? <Component {...props} /> : <Redirect to="/login" />
    //   }
    // />

    <Route
      {...restOfProps}
      render={props => {
        const currentUser = AuthService.currentUserValue;
        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.roles) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: currentUser.homepage }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
