import '../../App.css';
import React, { FC } from "react";


export const  LoginMaster: FC<{}> = ({ children}) =>  {
  
  const pageTitle ="VIRTUAL DEALER MANAGEMENT";
  return (
    <div className="container-fluid login">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 pull-right login-form">            
            <main className="Content">
              { children }
            </main>
          </div>

        </div>
      </div>
  );
}
