import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { useLocation, useHistory, Link } from "react-router-dom";
import Notification from "../img/noti.png";
import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import Moment from "react-moment";
import Axios from "axios";

import "./user-info.scss";
import AuthService from "../../../services/auth.service";
import { ChatState } from "../../../services/socket";
import { owner, presenter, seller } from "../../../utils/constants";

const UserInfo = props => {
  const { REACT_APP_BACKEND_SERVER_URL } = process.env;

  let history = useHistory();
  //const [socket, setSocket] = useState(io(`${REACT_APP_BACKEND_SERVER_URL}`));
  const [open, setOpen] = useState(false);
  const [shown, setShow] = useState({});

  // const [notifications, setNotifications] = useState([{ senderName:"ahmed", type:"liked" }]);

  const user = AuthService.currentUserValue;
  const count = props.notifications ? props.notifications.length : false;
  const logOut = () => {
    AuthService.logout();
    // history.push("/login");
    window.location.href = "https://www.virtualdealermanagement.com/login";
  };
  const {
    // user,
    //setUser,
    notifications,
    setNotifications,
    addToTimeline,
    Displayed,
    OsetDisplayed,
    Timeline,
    SetTimeline,
    messages,
    setMessages,
    socket,
    socketConnected,
    connectsocket,
    getTimeline
  } = ChatState();

  //console.log(Timeline);

  const displayNotification = ({ title, message, roles, id, link, mid }) => {
    // console.log({mid});
    // console.log( <Moment unix>{mid}</Moment> );
    return (
      // <div className="card" key={mid}>
      //         <h4>15:22</h4>
      //         <p>{`${title}: ${message} `}</p>
      //       </div>

      <li key={mid}>
        <div className="time">
          <Moment unix>{mid}</Moment>{" "}
        </div>
        <p>{message}</p>
      </li>
    );
  };

  const handleRead = () => {
    SetTimeline([]);
    setOpen(false);
  };

  useEffect(() => {
    console.log("in notification use eefect:", notifications);
    if (Array.isArray(notifications)) {
      //MEANS ITS ARRAY
      //   if(notifications.length>0){
      //     notifications.map((n) => {
      //       console.log(n);
      //     if (!Displayed.includes(n.mid)) {
      //       addToTimeline(n);
      //       OsetDisplayed((prev) => [...prev, n.mid]);
      //     }
      //   });
      // }
    } //not array
    else {
      if (Object.keys(notifications).length === 0) {
      } else {
        console.log("notification has keys");
        if (!Displayed.includes(notifications.mid)) {
          console.log("adding in setdisplayed");
          toastNotification(notifications);
          SetTimeline(prev => [...prev, notifications]);
          //   setShow(notifications);
          // SetTimeline((prev) => [...prev, notifications]);
          // OsetDisplayed((prev) => [...prev, notifications.mid]);
        }
      }
      // //   if(!Object.keys(notifications).length === 0 )
      // //   { console.log("notification has keys");
      // //   if (!Displayed.includes(notifications.mid)) {
      // //     console.log("adding in setdisplayed");
      // //    // toastNotification(notifications);
      // //  //   setShow(notifications);
      // //     // SetTimeline((prev) => [...prev, notifications]);
      // //      OsetDisplayed((prev) => [...prev, notifications.mid]);
      // //   }
      // }
    }
    return () => {
      OsetDisplayed([]); // This worked for me
    };
  }, [notifications]);

  const toastNotification = n => {
    if (n.roles == presenter) {
      console.log("Message for presenter");
      addNotification({
        title: n.title,
        subtitle: n.message,
        message: "Join",
        theme: "darkblue",
        //native:true,
        duration: 6000,
        onClick: async () => {
          Axios.post(
            `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/CheckStatus`,
            {
              p_order_id: n.id
            },
            {
              headers: { "x-access-token": user.accessToken }
            }
          ).then(
            responseCheckStatus => {
              if (responseCheckStatus.data.success) {
                if (responseCheckStatus.data.details) {
                  //if the param details is there
                  let presentationdetails = responseCheckStatus.data.details;
                  if (presentationdetails.status === 1) {
                    // the presentation is still pending someone needs to accept

                    Axios.post(
                      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Accept`,
                      {
                        p_order_id: n.id,
                        p_presenter_id: user.user_id
                      },
                      {
                        headers: { "x-access-token": user.accessToken }
                      }
                    ).then(
                      response => {
                        if (response.data.success) {
                          let path = `/Presentation/${n.link}/${user.username}`;
                          history.push({
                            pathname: path,
                            state: {
                              p_order_id: n.id,
                              p_user_role: "Presenter"
                            }
                          });
                        } else {
                          alert(response.data.message);
                        }
                      },
                      error => {
                        const _content =
                          (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                          error.message ||
                          error.toString();
                        console.log(_content);
                        //setContent(_content);

                        if (
                          (error.response && error.response.status === 401) ||
                          (error.response && error.response.status === 403)
                        ) {
                          AuthService.logout();
                          history.push("/login");
                          //EventBus.dispatch("logout");
                        }
                      }
                    );
                  } else if (presentationdetails.status === 2) {
                    //since this  the presentation is inprogress, check if i am able to join the presentation, if i accepted and left it by chance
                    //since this page is only showing presentations with available status we should skip it
                  }
                }
              } else {
                alert(responseCheckStatus.data.message);
              }
            },
            error => {
              const _content =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(_content);
              //setContent(_content);

              if (
                (error.response && error.response.status === 401) ||
                (error.response && error.response.status === 403)
              ) {
                AuthService.logout();
                history.push("/login");
                //EventBus.dispatch("logout");
              }
            }
          );
        }
      });
    }

    if (n.roles == owner) {
      console.log("Message for Owner");
      addNotification({
        title: n.title,
        subtitle: n.message,
        theme: "red",
        //native:true,
        duration: 6000
      });
    }

    if (n.roles == seller) {
      console.log("Message for seller");
      addNotification({
        title: n.title,
        subtitle: n.message,
        theme: "light",
        //native:true,
        duration: 6000
      });
    }
  };
  useEffect(() => {
    console.log("displayed ids", Displayed);
  }, [Displayed]);
  //   useEffect(() => {
  //     console.log("in meesage useeffect:",user,messages);
  //     messages.map((n) => {
  //       if(n.roles == user.roles)
  //       {
  //         console.log(n.roles ,user.roles);
  //       setNotifications(n)
  //       }
  //     });
  //     return () => {
  //       setNotifications({})
  //     };
  //   },[messages]);

  // useEffect(() => {
  //   console.log("in notification use eefect:",user,notifications,notifications.length);
  //   if(Array.isArray(notifications)) //MEANS ITS ARRAY
  //   {
  //   //   if(notifications.length>0){
  //   //     notifications.map((n) => {
  //   //       console.log(n);
  //   //     if (!Displayed.includes(n.mid)) {
  //   //       addToTimeline(n);
  //   //       OsetDisplayed((prev) => [...prev, n.mid]);
  //   //     }
  //   //   });
  //   // }
  //   }
  //   else //not array
  //   {
  //     if(!Object.keys(notifications).length === 0)
  //     {
  //     if (!Displayed.includes(notifications.mid)) {
  //    //   setShow(notifications);
  //       SetTimeline((prev) => [...prev, notifications]);
  //       OsetDisplayed((prev) => [...prev, notifications.mid]);
  //     }
  //   }

  //   }

  //   return () => {
  //     //setShow({});

  //   };
  // },[notifications]);

  // useEffect(() => {
  //   console.log("in displayed messages:",Displayed,Timeline);
  //   return () => {
  //     //setShow({});

  //   };
  // },[Displayed,Timeline]);

  // useEffect(() => {
  //   console.log("in shown:",shown);
  //   let n=shown;
  //   if (shown.roles == presenter) {
  //             console.log("Message for presenter");
  //             addNotification({
  //               title: n.title,
  //               subtitle: n.message,
  //               message: "Join",
  //               theme: "darkblue",
  //               //native:true,
  //               duration: 6000,
  //               onClick: async () => {
  //                 Axios.post(
  //                   `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/CheckStatus`,
  //                   {
  //                     p_order_id: n.id
  //                   },
  //                   {
  //                     headers: { "x-access-token": user.accessToken }
  //                   }
  //                 ).then(
  //                   responseCheckStatus => {
  //                     if (responseCheckStatus.data.success) {
  //                       if (responseCheckStatus.data.details) {
  //                         //if the param details is there
  //                         let presentationdetails =
  //                           responseCheckStatus.data.details;
  //                         if (presentationdetails.status === 1) {
  //                           // the presentation is still pending someone needs to accept

  //                           Axios.post(
  //                             `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Accept`,
  //                             {
  //                               p_order_id: n.id,
  //                               p_presenter_id: user.user_id
  //                             },
  //                             {
  //                               headers: { "x-access-token": user.accessToken }
  //                             }
  //                           ).then(
  //                             response => {
  //                               if (response.data.success) {
  //                                 let path = `/Presentation/${n.link}/${user.username}`;
  //                                 history.push({
  //                                   pathname: path,
  //                                   state: {
  //                                     p_order_id: n.id,
  //                                     p_user_role: "Presenter"
  //                                   }
  //                                 });
  //                               } else {
  //                                 alert(response.data.message);
  //                               }
  //                             },
  //                             error => {
  //                               const _content =
  //                                 (error.response &&
  //                                   error.response.data &&
  //                                   error.response.data.message) ||
  //                                 error.message ||
  //                                 error.toString();
  //                               console.log(_content);
  //                               //setContent(_content);

  //                               if (
  //                                 (error.response &&
  //                                   error.response.status === 401) ||
  //                                 (error.response && error.response.status === 403)
  //                               ) {
  //                                 AuthService.logout();
  //                                 history.push("/login");
  //                                 //EventBus.dispatch("logout");
  //                               }
  //                             }
  //                           );
  //                         } else if (presentationdetails.status === 2) {
  //                           //since this  the presentation is inprogress, check if i am able to join the presentation, if i accepted and left it by chance
  //                           //since this page is only showing presentations with available status we should skip it
  //                         }
  //                       }
  //                     } else {
  //                       alert(responseCheckStatus.data.message);
  //                     }
  //                   },
  //                   error => {
  //                     const _content =
  //                       (error.response &&
  //                         error.response.data &&
  //                         error.response.data.message) ||
  //                       error.message ||
  //                       error.toString();
  //                     console.log(_content);
  //                     //setContent(_content);

  //                     if (
  //                       (error.response && error.response.status === 401) ||
  //                       (error.response && error.response.status === 403)
  //                     ) {
  //                       AuthService.logout();
  //                       history.push("/login");
  //                       //EventBus.dispatch("logout");
  //                     }
  //                   }
  //                 );
  //               }
  //             });
  //           }

  //         if (shown.roles == owner) {

  //             console.log("Message for Owner");
  //             addNotification({
  //               title: n.title,
  //               subtitle: n.message,
  //               theme: "red",
  //               //native:true,
  //               duration: 6000
  //             });

  //         }

  //         if (shown.roles == seller) {

  //             console.log("Message for seller");
  //             addNotification({
  //               title: n.title,
  //               subtitle: n.message,
  //               theme: "light",
  //               //native:true,
  //               duration: 6000
  //             });

  //         }

  // },[shown]);

  return (
    <div className="UserInfo">
      <Notifications position={"bottom-right"} />

      {open && (
        <div className="timeline">
          <div className="wrapper">
            <h1> Notifications</h1>
            <ul className="sessions">
              {Timeline.map(n => displayNotification(n))}
              {Timeline.length > 0 && (
                <div className="bottom">
                  <button className="btn" onClick={handleRead}>
                    Mark as read
                  </button>
                </div>
              )}
              {Timeline.length == 0 && <label>No New Notifications</label>}
            </ul>
          </div>
        </div>
      )}

      <button className="UserInfo-notifications" onClick={() => setOpen(!open)}>
        <i className="fa fa-envelope-o" />
        {Timeline.length > 0 && (
          <span className="UserInfo-notifications-count">
            {Timeline.length}
          </span>
        )}
      </button>

      <img className="UserInfo-image" src={props.image} />

      <strong className="UserInfo-name">{props.userName}</strong>
      <button className="" onClick={logOut}>
        Logout
      </button>
    </div>
  );
};

UserInfo.propTypes = {
  image: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  notifications: PropTypes.array
};

export default UserInfo;
