import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import Axios from "axios";
import Box from "../../bootstrap_components/Box";
import { useLocation, useHistory, Link } from "react-router-dom";
import { render } from "react-dom";
import AuthService from "../../services/auth.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as constantsClass from "../../utils/constants";

// import Cookies from "js-cookie";
// import { SessionContext, getSessionCookie, setSessionCookie } from "../../session";
import "dotenv/config";
const {
  REACT_APP_BACKEND_SERVER_URL,
  REACT_APP_EMAIL_FOOTER,
  REACT_APP_FRONTEND_SERVER_URL
} = process.env;

const ForgotPassword = props => {
  let history = useHistory();
  const location = useLocation();
  const [emailRecoverySubject, setEmailRecoverySubject] = useState("");
  const [emailRecoveryContent, setEmailRecoveryContent] = useState("");
  const getEmailContent = async () => {
    try {
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Email/getContent`, {
        p_content_id: 6
      }).then(response => {
        setEmailRecoverySubject(response.data.results[0][0].email_subject);
        setEmailRecoveryContent(response.data.results[0][0].email_content_text);
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  const sendEmailRecovery = (emailRecoverCode, email, userFullName) => {
    try {
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/send/email`, {
        p_to_email: email,
        p_email_subject: emailRecoverySubject,
        p_email_content: `DEAR <strong>${userFullName}</strong>, <br/><br/><a href="${REACT_APP_FRONTEND_SERVER_URL}/ResetPassword/${emailRecoverCode}" target="_blank">CLICK HERE</a> ${emailRecoveryContent}<br/><br/><br/>${REACT_APP_EMAIL_FOOTER}`
      }).then(response => {});
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getEmailContent();
    return () => {};
  }, []);
  return (
    <div>
      <h3>Forgot Password</h3>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
          .min(6, 'Too Short! Min 6 characters')
          .max(50, 'Too Long!')
          .email("Invalid email")
          .required("Required")
        })}
        onSubmit={({ email }, { setStatus, setSubmitting }) => {
          setStatus();

          Axios.post(
            `${REACT_APP_BACKEND_SERVER_URL}/api/Users/AccountExists`,
            {
              p_email: email
            }
          ).then(response => {
            var userFullName = "";
            if (response.data.success) {
              userFullName = response.data.p_full_name;
              Axios.post(
                `${REACT_APP_BACKEND_SERVER_URL}/api/Users/GenerateRecoveryCode`,
                {
                  p_email: email
                }
              ).then(response => {
                if (response.data.success)
                  sendEmailRecovery(
                    response.data.p_email_recovery_code,
                    email,
                    userFullName
                  );
                setSubmitting(false);
                setStatus(response.data.message);
              });
            } else {
              setSubmitting(false);
              setStatus(response.data.message);
            }
          });
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                name="email"
                type="email"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Recover Account
              </button>
              {isSubmitting && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </div>

            {status && <div className={"alert alert-danger"}>{status}</div>}
          </Form>
        )}
      />
    </div>
  );
};

export default ForgotPassword;
