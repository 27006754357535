import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { PropTypes } from "prop-types";
import Axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import Box from "../../bootstrap_components/Box";
import DataTable from "../../bootstrap_components/DataTable";
import MyEmail from "../Email/Email";
import { renderEmail } from "react-html-email";
import * as constantsClass from "../../utils/constants";
import AuthService from "../../services/auth.service";

import "dotenv/config";
const {
  REACT_APP_BACKEND_SERVER_URL,
  REACT_APP_ACCOUNTING_EMAIL_ADDRESS,
  REACT_APP_EMAIL_FOOTER
} = process.env;

const PresentationFinalization = props => {
  const location = useLocation();
  const history = useHistory();

  const user = AuthService.currentUserValue;
  //console.log(location.state);

  const [
    order_actualPrice_insurances,
    setorder_actualPrice_insurances
  ] = useState(0);
  const [
    order_sellingPrice_insurances,
    setorder_sellingPrice_insurances
  ] = useState(0);
  const [orderProfit, setorderProfit] = useState(0);

  const [gap_profit, setgap_profit] = useState(0);
  const [ecs_profit, setecs_profit] = useState(0);
  const [ah_profit, setah_profit] = useState(0);
  const [cl_profit, setcl_profit] = useState(0);

  const [gap, setgap] = useState(0);
  const [ecs, setecs] = useState(0);
  const [ah, setah] = useState(0);
  const [cl, setcl] = useState(0);

  const [gap_selling, setgap_selling] = useState(0);
  const [ecs_selling, setecs_selling] = useState(0);
  const [ah_selling, setah_selling] = useState(0);
  const [cl_selling, setcl_selling] = useState(0);

  const [customerName, setCustomerName] = useState("");
  const [dealerContact, setDealerContact] = useState("");
  const [toDealerEmail, setToDealerEmail] = useState("");
  const [platformOwnerEmail] = useState(
    `${REACT_APP_ACCOUNTING_EMAIL_ADDRESS}`
  );
  const [viewURL, setViewURL] = useState("");
  const [downloadURL, setDownloadURL] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  const [orderInfo, set_values] = useState({
    orderId: "0",
    presenterID: "",
    customerName: "0",
    customerEmail: "0",
    stockNo: "0",
    year: "0",
    make: "0",
    model: "0",
    mileage: "0",
    dealerContact: "",
    toDealerEmail: ""
  });
  const getVideoComposition = async () => {
    try {
      var view_url = "";
      var download_url = "";
      const response_view = await Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Twilio/Composition/View`,
        {
          p_order_id: location.state.p_order_id,
          p_composition_sid: location.state.p_composition_sid
        },
        {
          headers: { "x-access-token": user.accessToken }
        }
      );
      if (response_view.data.success) {
        view_url = `<br/><br/><a href="${response_view.data.video_url}" target="_blank">VIEW VIDEO MEETING</a>`;
      }
      const response_download = await Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Twilio/Composition/Download`,
        {
          p_order_id: location.state.p_order_id,
          p_composition_sid: location.state.p_composition_sid
        },
        {
          headers: { "x-access-token": user.accessToken }
        }
      );
      if (response_download.data.success) {
        download_url = `<br/><br/><a href="${response_download.data.video_url}" target="_blank">DOWNLOAD VIDEO MEETING</a>`;
      }
      sendEmail(view_url, download_url);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getEmailContent = () => {
    try {
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Email/getContent`, {
        p_content_id: 1
      }).then(response => {
        setEmailSubject(response.data.results[0][0].email_subject);
        setEmailContent(response.data.results[0][0].email_content_text);
        //console.log(temp);
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getEmailContent();
    return () => {};
  }, []);

  //const form = useRef();

  const sendEmail = (view_url, download_url) => {
    try {
      //console.log(emailContent)
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/send/email`, {
        p_to_email: [platformOwnerEmail, toDealerEmail],
        p_email_subject: emailSubject,
        p_email_content: `${dealerContact} AND ${customerName} ${emailContent} <br/><br/><br/>${REACT_APP_EMAIL_FOOTER}`
      }).then(response => {
        if (response.data.success) {
          let path = `/PresentationAvailable`;
          history.push({
            pathname: path,
            state: { p_user_role: "Presenter" }
          });
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const generateInvoiceAndSendEmail = () => {
    try {
      //console.log(emailContent)
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/generate/invoice`, {
        p_order_id: location.state.p_order_id,
        p_to_email: [platformOwnerEmail, toDealerEmail],
        p_email_subject: emailSubject,
        p_email_content: `${dealerContact} AND ${customerName} ${emailContent} <br/><br/><br/>${REACT_APP_EMAIL_FOOTER}`
      }).then(response => {
        if (response.data.success) {
          let path = `/PresentationAvailable`;
          history.push({
            pathname: path,
            state: { p_user_role: "Presenter" }
          });
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const completePresentation = async () => {
    await Axios.post(
      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Complete`,
      {
        p_Order_id: orderInfo.orderId,
        presenter_id: orderInfo.presenterID,
        p_actual_cost: order_actualPrice_insurances,
        p_profit: orderProfit,
        p_esc_selling_price: ecs_selling,
        p_gap_selling_price: gap_selling,
        p_ah_selling_price: ah_selling,
        p_cl_selling_price: cl_selling
      },
      {
        headers: { "x-access-token": user.accessToken }
      }
    ).then(response => {
      generateInvoiceAndSendEmail();
    });
  };
  const getOrderInfo = () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/getOne`,
        {
          p_Order_id: location.state.p_order_id
        },
        {
          headers: { "x-access-token": user.accessToken }
        }
      ).then(response => {
        //console.log(response);
        setorder_sellingPrice_insurances(
          response.data.results[0][0].selling_cost
        );
        setgap(
          response.data.results[0][0].GAP == null
            ? 0
            : response.data.results[0][0].GAP
        );
        setecs(
          response.data.results[0][0].ECS == null
            ? 0
            : response.data.results[0][0].ECS
        );
        setah(
          response.data.results[0][0].AH == null
            ? 0
            : response.data.results[0][0].AH
        );
        setcl(
          response.data.results[0][0].CL == null
            ? 0
            : response.data.results[0][0].CL
        );
        setCustomerName(response.data.results[0][0].customer_name);
        setDealerContact(response.data.results[0][0].dealer_poc_name);
        setToDealerEmail(response.data.results[0][0].dealer_account_email);
        //if old ecs
        set_values({
          orderId: location.state.p_order_id,
          presenterID: response.data.results[0][0].presenter_id,
          customerName: response.data.results[0][0].customer_name,
          customerEmail: response.data.results[0][0].customer_email,
          //dealerContact: response.data.results[0][0].dealer_poc_name,
          //toDealerEmail: response.data.results[0][0].dealer_account_email,
          stockNo: response.data.results[0][0].stockNo,
          year: response.data.results[0][0].year,
          make: response.data.results[0][0].make,
          model: response.data.results[0][0].model,
          mileage: response.data.results[0][0].miles

          //emailContent: `${response.data.results[0][0].dealer_poc_name}, ${response.data.results[0][0].customer_name} JUST COMPLETED THEIR VIRTUAL DELIVERY! THE VIDEO FILE, AS WELL AS A PROFIT RECAP IS ATTACHED TO THIS EMAIL. LET US KNOW IF YOU HAVE ANY QUESTIONS. GLAD WE COULD. CAN’T WAIT FOR THE NEXT DEAL. HAVE A GREAT DAY.\n\n\nVIRTUAL DEALER MANAGEMENT`
        });
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getOrderInfo();
    //console.log(orderInfo)
    return () => {};
  }, []);
  useEffect(() => {
    // another side-effect on change of stateX or stateY
    // calc_total(newValues);
    setorder_actualPrice_insurances(
      gap_selling + ecs_selling + ah_selling + cl_selling
    );
    setorderProfit(
      parseFloat(gap_profit) +
        parseFloat(ecs_profit) +
        parseFloat(cl_profit) +
        parseFloat(ah_profit)
    );
    console.log(orderProfit, order_actualPrice_insurances);
    //console.log(order_actualPrice_insurances,order_sellingPrice_insurances,gap_profit,ecs_profit,ah_profit,cl_profit,orderProfit);
  });
  const value_handler = e => {
    let name = e.target.name;
    let value = e.target.value === "" ? "0" : e.target.value;
    const newValues = {
      ...orderInfo,
      [name]: value
    };
    set_values(newValues);
    // Calling the method to sum the value
  };

  const calc_total = newValues => {
    // const { gap, ecs, ah, cl } = newValues;
    // const monthlyPaymentWithInsurances =
    // amountFInanced + parseFloat(gap) + parseFloat(ecs) + parseFloat(ah) + parseFloat(cl)+pmtRate;
    // setpaymentWithInsurances(Math.round(monthlyPaymentWithInsurances/termsInMonths));
  };
  return (
    <Box>
      <div className="row">
        <div className="col-md-12">
          <h2>Post Presentation</h2>
        </div>
        <div className="col-md-12">
          <div className="col-md-4">
            <label className="Box-title Box--orange">Purchase Info</label>
            <Box>
              <div className="row">
                <p></p>
                <div className="col-md-12">
                  <strong>
                    <label>Customer Name: </label>
                    <label>{orderInfo.customerName}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-12">
                  <strong>
                    <label>Stock #: </label>
                    <label>{orderInfo.stockNo}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-12">
                  <strong>
                    <label>Year: </label>
                    <label>{orderInfo.year}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-12">
                  <strong>
                    <label>Make: </label>
                    <label>{orderInfo.make}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-12">
                  <strong>
                    <label>Model: </label>
                    <label>{orderInfo.model}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-12 ">
                  <strong>
                    <label>Milage: </label>
                    <label>{orderInfo.mileage}</label>
                  </strong>
                </div>
              </div>
            </Box>
          </div>

          <div className="col-md-8">
            <label className="Box-title Box--blue">Profit Info</label>
            <Box>
              <div className="row">
                <div className="col-md-4">
                  <strong>GAP</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="gap"
                    value={gap}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <strong>Actual Cost</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="gap_selling"
                    value={gap_selling}
                    onChange={e => {
                      setgap_selling(parseFloat(e.target.value));
                      setgap_profit(gap - parseFloat(e.target.value));
                    }}
                    disabled={parseFloat(gap) == 0 ? true : false}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Profit</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="gap_profit"
                    value={gap_profit}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <strong>ECS</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ecs"
                    disabled
                    value={ecs}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Actual Cost</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ecs_selling"
                    value={ecs_selling}
                    onChange={e => {
                      setecs_selling(parseFloat(e.target.value));
                      setecs_profit(ecs - parseFloat(e.target.value));
                    }}
                    disabled={parseFloat(ecs) == 0 ? true : false}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Profit</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ecs_profit"
                    value={ecs_profit}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <strong>AH</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ah"
                    disabled
                    value={ah}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Actual Cost</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ah_selling"
                    value={ah_selling}
                    onChange={e => {
                      setah_selling(parseFloat(e.target.value));
                      setah_profit(ah - parseFloat(e.target.value));
                    }}
                    disabled={parseFloat(ah) == 0 ? true : false}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Profit</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="ah_profit"
                    value={ah_profit}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <strong>CL</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="cl"
                    disabled
                    value={cl}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Actual Cost</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="cl_selling"
                    value={cl_selling}
                    onChange={e => {
                      setcl_selling(parseFloat(e.target.value));
                      setcl_profit(cl - parseFloat(e.target.value));
                    }}
                    disabled={parseFloat(cl) == 0 ? true : false}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Profit</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="cl_profit"
                    value={cl_profit}
                    disabled
                  />
                </div>
              </div>
            </Box>
            <div className="col-md-12">&nbsp;</div>
            <div className="col-md-4">
              <strong>
                <label>Total Insurance Cost: </label>
              </strong>
              <label>{order_sellingPrice_insurances}</label>
            </div>
            <div className="col-md-4">
              <strong>
                <label>Profit: </label>
              </strong>
              <label>{orderProfit}</label>
            </div>
            <div className="col-md-4">
              <input
                className="Form-button"
                type="submit"
                value="Save & Send Invoice"
                onClick={() => {
                  completePresentation();
                }}
              />
              {/* <input
                className="Form-button"
                type="button"
                value="Send Email"
                onClick={() => {
                  sendEmail();
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PresentationFinalization;
