import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { PropTypes } from "prop-types";
import Axios from "axios";
import Box from "../../bootstrap_components/Box";
import DataTable from "../../bootstrap_components/DataTable";
import { useLocation, useHistory } from "react-router-dom";
import MyEmail from "../Email/Email";
import { renderEmail } from "react-html-email";
import * as constantsClass from "../../utils/constants";
import * as sendEmail from "../../utils/sendEmail";
import AuthService from "../../services/auth.service";

import "dotenv/config";
const {
  REACT_APP_BACKEND_SERVER_URL,
  REACT_APP_FRONTEND_SERVER_URL
} = process.env;

const submitRe = () => {
  Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Users/Authenticate`, {
    username: "test",
    password: "test"
  }).then(response => {
    console.log(response);
  });
};
const Presentation = props => {
  let history = useHistory();
  const location = useLocation();

  const user = AuthService.currentUserValue;
  const [gapTaxable, setGAPTaxable] = useState("");
  const [ecsTaxable, setECSTaxable] = useState("");
  const [ahTaxable, setAHTaxable] = useState("");
  const [clTaxable, setCLTaxable] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInviteHidden, setIsInviteHidden] = useState(true);
  const [isUpdateHidden, setIsUpdateHidden] = useState(true);
  //console.log(location.state.p_user_role);

  // order info
  const [orderID, setOrderId] = useState("");
  const [presenterID, setpresennterID] = useState("");
  const [customerName, setcustomerName] = useState("");
  const [customerEmail, setcustomerEmail] = useState("");
  const [stockNo, setstockNo] = useState("");
  const [year, setyear] = useState("");
  const [make, setmake] = useState("");
  const [model, setmodel] = useState("");
  const [mileage, setmileage] = useState("");

  const [paymentwithAPR, setpaymentwithAPR] = useState(0);
  const [termsInMonths, settermsInMonths] = useState(0);
  const [amountFInanced, setAmountFinanced] = useState(0);
  const [pmtRate, setPmtRate] = useState(0);
  const [paymentWithInsurances, setpaymentWithInsurances] = useState(0);

  const [dealerName, setDealerName] = useState("");
  const [dealerLogo, setDealerLogo] = useState("");
  const [presentationLink, setpresentationLink] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  // const sendEmailInvitation = async () => {

  // 	const messageHtml =  renderEmail(
  // 		<MyEmail invitationLink={`${REACT_APP_FRONTEND_SERVER_URL}/room/${presentationLink}/${customerName}`} isInvite="block">M/S {customerName} , {emailContent}</MyEmail>
  // 	);
  // 	await sendEmail.sendEmail("VOIP", customerEmail, emailSubject, messageHtml)
  // }

  // for auto calculations
  const [total, set_total] = useState(0);
  const [values, set_values] = useState({
    gap: "0",
    ecs: "0",
    ah: "0",
    cl: "0"
  });
  const updatePayment = bDirect => () => {
    Axios.post(
      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/UpdatePayment`,
      {
        p_Order_id: orderID,
        presenter_id: presenterID,
        p_ECS: values.ecs,
        p_GAP: values.gap,
        p_AH: values.ah,
        p_CL: values.cl,
        p_monthlypaymentAPR: paymentWithInsurances
      },
      {
        headers: { "x-access-token": user.accessToken }
      }
    ).then(response => {
      console.log(response);
      if (bDirect) {
        try{
          composeVideoAndRedirect()
        }catch(error){
          console.error(error.message);
        }
        // let path = `/PresentationFinalization`;
        // history.push({
        //   pathname: path,
        //   state: { p_order_id: orderID, p_user_role: user.roles }
        // });
      }
    });

  };

  const composeVideoAndRedirect = async () => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Twilio/Compose/Video`, 
        {
          p_order_id: orderID,
          p_room_name: presentationLink
        },
        {
          headers: { "x-access-token": user.accessToken }
        })
        .then(response => {
          let path = `/PresentationFinalization`;
          history.push({
            pathname: path,
            state: { p_order_id: orderID, p_user_role: user.roles, p_composition_sid: response.data.p_composition_sid }
          });
        });
      }catch (error) {
        console.error(error.message);
    }
  };
  // const sendEmailInvitation = async () => {
  //   const messageHtml = renderEmail(
  //     <MyEmail
  //       invitationLink={`${REACT_APP_FRONTEND_SERVER_URL}/room/${presentationLink}/${customerName}`}
  //       isInvite="block"
  //     >
  //       M/S {customerName} ,{emailContent}
  //     </MyEmail>
  //   );
  //   await sendEmail.sendEmail("VOIP", customerEmail, emailSubject, messageHtml);
  // };
  // const form = useRef();

  // const sendEmail = e => {
  //   e.preventDefault();
  //   console.log(form.current);
  //   emailjs
  //     .sendForm(
  //       "service_un1ip8h",
  //       "template_eto8zpo",
  //       form.current,
  //       "3F3oBcRsR1NUegvRR"
  //     )
  //     .then(
  //       result => {
  //         console.log(result.text);
  //       },
  //       error => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  // for auto calculations

  const value_handler = e => {
    let name = e.target.name;
    let value = e.target.value === "" ? "0" : e.target.value;
    const newValues = {
      ...values,
      [name]: value
    };
    set_values(newValues);

    // Calling the method to sum the value
    calc_total(newValues);
  };

  const calc_total = newValues => {
    const { gap, ecs, ah, cl } = newValues;
    const monthlyPaymentWithInsurances =
      amountFInanced +
      parseFloat(gap) +
      parseFloat(ecs) +
      parseFloat(ah) +
      parseFloat(cl) +
      pmtRate;
    setpaymentWithInsurances(
      Math.round(monthlyPaymentWithInsurances / termsInMonths)
    );
  };
  const getEmailContent = async () => {
    try {
      await Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/Email/getContent`, {
        p_content_id: 3 // 4 is Customer's Role ID.
      }).then(response => {
        setEmailSubject(response.data.results[0][0].email_subject);
        setEmailContent(response.data.results[0][0].email_content_text);
        //console.log(temp);
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getEmailContent();
    return () => {};
  }, []);

  const getOrderInfo = async () => {
    try {
      if (location.state.p_user_role == "Presenter") {
        setIsDisabled(false);
        setIsUpdateHidden(false);
      } else {
        setIsInviteHidden(false);
      }

      if (location.state.p_user_role) {
        console.log("order id", location.state.p_order_id);
        await Axios.post(
          `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/getOne`,
          {
            p_Order_id: location.state.p_order_id
          },
          {
            headers: { "x-access-token": user.accessToken }
          }
        ).then(response => {
          console.log(response);
          setOrderId(location.state.p_order_id);
          if (location.state.p_user_role == "Presenter") {
            setpresennterID(response.data.results[0][0].presenter_id);
          }
          //console.log(response);
          setcustomerName(response.data.results[0][0].customer_name);
          setcustomerEmail(response.data.results[0][0].customer_email);
          setpresentationLink(response.data.results[0][0].presentation_link);
          setstockNo(response.data.results[0][0].stockNo);
          setyear(response.data.results[0][0].year);
          setmake(response.data.results[0][0].make);
          setmodel(response.data.results[0][0].model);
          setmileage(response.data.results[0][0].miles);
          settermsInMonths(response.data.results[0][0].term_in_month);
          setAmountFinanced(response.data.results[0][0].amount_financed);
          setPmtRate(response.data.results[0][0].pmt_rate);
          setpaymentwithAPR(response.data.results[0][0].payment_apr);
          setpaymentWithInsurances(response.data.results[0][0].payment_apr);
          setDealerLogo(response.data.results[0][0].dealer_logo);
          setDealerName(response.data.results[0][0].dealer_name);
          
          //if old ecs
          set_values({
            gap:
              response.data.results[0][0].GAP == null
                ? 0
                : response.data.results[0][0].GAP,
            ecs:
              response.data.results[0][0].ECS == null
                ? 0
                : response.data.results[0][0].ECS,
            ah:
              response.data.results[0][0].AH == null
                ? 0
                : response.data.results[0][0].AH,
            cl:
              response.data.results[0][0].CL == null
                ? 0
                : response.data.results[0][0].CL
          });
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getOrderInfo();
    return () => {
      setcustomerName({});
      setcustomerEmail({});
      setstockNo({});
      setyear({});
      setmake({});
      setmodel({});
      setmileage({});
      settermsInMonths({});
      settermsInMonths({});
      setAmountFinanced({});
      setPmtRate({});
    };
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     getOrderInfo();
  //   };

  //   fetchData();
  // }, []);

  return (
    <Box>
      <div className="row">
        <div className="col-md-12">
          <form className="Form">
            <img className="UserInfo-image" src={dealerLogo} />
            <label>{dealerName}</label>
            <label className="Box-title Box--orange">Purchase Info</label>
            <Box>
              <div className="row">
                <div className="col-md-12">
                  <strong>
                    <label>Customer Name: </label>
                    <label>{customerName}</label>
                  </strong>
                </div>
                <div className="col-md-12">
                  <strong>
                    <label>Stock #: </label>
                    <label>{stockNo}</label>
                  </strong>
                </div>
                <div className="col-md-6">
                  <strong>
                    <label>Year: </label>
                    <label>{year}</label>
                  </strong>
                </div>
                <div className="col-md-6">
                  <strong>
                    <label>Make: </label>
                    <label>{make}</label>
                  </strong>
                </div>
                <div className="col-md-6">
                  <strong>
                    <label>Model: </label>
                    <label>{model}</label>
                  </strong>
                </div>
                <div className="col-md-6">
                  <strong>
                    <label>Milage: </label>
                    <label>{mileage}</label>
                  </strong>
                </div>
                <p></p>
                <div className="col-md-6">
                  <strong>GAP</strong>
                  <input
                    className="Form-field"
                    type="text"
                    name="gap"
                    value={values.gap}
                    disabled={isDisabled}
                    onChange={value_handler}
                  />
                  <input
                    type="checkbox"
                    name="gapTaxable"
                    checked
                    disabled={isDisabled}
                    onChange={e => {
                      setGAPTaxable(e.target.value);
                    }}
                  />
                  Taxable
                </div>
                <div className="col-md-6">
                  <strong>ESC</strong>
                  <input
                    className="Form-field"
                    type="text"
                    name="ecs"
                    value={values.ecs}
                    disabled={isDisabled}
                    onChange={value_handler}
                  />
                  <input
                    type="checkbox"
                    name="ecsTaxable"
                    checked
                    disabled={isDisabled}
                    onChange={e => {
                      setECSTaxable(e.target.value);
                    }}
                  />
                  Taxable
                </div>
                <div className="col-md-6">
                  <strong>AH</strong>
                  <input
                    className="Form-field"
                    type="text"
                    name="ah"
                    value={values.ah}
                    disabled={isDisabled}
                    onChange={value_handler}
                  />
                  <input
                    type="checkbox"
                    name="ahTaxable"
                    checked
                    disabled={isDisabled}
                    onChange={e => {
                      setAHTaxable(e.target.value);
                    }}
                  />
                  Taxable
                </div>
                <div className="col-md-6">
                  <strong>CL</strong>
                  <input
                    className="Form-field"
                    type="number"
                    name="cl"
                    disabled={isDisabled}
                    value={values.cl}
                    onChange={value_handler}
                  />
                  <input
                    type="checkbox"
                    name="clTaxable"
                    checked
                    disabled={isDisabled}
                    onChange={e => {
                      setCLTaxable(e.target.value);
                    }}
                  />
                  Taxable
                </div>
                <div className="col-md-6">
                  <strong>Terms (in Months)</strong>
                  <input
                    className="Form-field"
                    type="text"
                    name="termsInMonths"
                    value={termsInMonths}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <strong>Payment</strong>
                  <input
                    className="Form-field"
                    type="text"
                    name="Payment"
                    value={paymentWithInsurances}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <input
                    className="Form-button"
                    type="button"
                    hidden={isUpdateHidden}
                    value="Update Payment"
                    onClick={updatePayment(false)}
                  />
                  <br/>
                  <br/>
                  <input
                    className="Form-button"
                    type="button"
                    hidden={isUpdateHidden}
                    value="End Session"
                    onClick={updatePayment(true)}
                  />
                </div>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default Presentation;
