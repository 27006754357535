import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import SummaryBoxRow from "./SummaryBoxRow";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { Chart } from "react-google-charts";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Axios from "axios";
import { subDays } from "date-fns";
import * as constantsClass from "../../utils/constants";
import "dotenv/config";
import AuthService from "../../services/auth.service";

const { REACT_APP_BACKEND_SERVER_URL } = process.env;

const DashboardSeller = props => {
  const [loading, setLoading] = useState(true);
  const [presentations, setpresentations] = useState("0");
  const [completed, setcompleted] = useState("0");
  const [pending, setpending] = useState("0");
  const [netProfit, setnetProfit] = useState("0");
  const [chart, setChartArray] = useState([]);
  const [data1, setdata] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [presenter, setPresenter] = useState("");
  const [value, onChange] = useState([subDays(new Date(), 30), new Date()]);
  const user = AuthService.currentUserValue;
  //console.log(user);
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };
  const columns = [
    {
      name: "Stock #",
      selector: row => row.stockNo
    },
    {
      name: "Year",
      selector: row => row.year
    },
    {
      name: "Make",
      selector: row => row.make
    },
    {
      name: "Model",
      selector: row => row.model
    },
    {
      name: "Mileage",
      selector: row => row.miles
    },
    {
      name: "Price",
      selector: row => row.price
    },
    {
      name: "Profit",
      selector: row => row.profit
    }
  ];
  const data = [
    {
      stock_no: "Yatch",
      year: "2016",
      make: "1",
      model: "ze22",
      miles: "22,000",
      price: "200,000",
      profit: "20,000"
    },
    {
      stock_no: "Car",
      year: "2020",
      make: "2",
      model: "Honda",
      miles: "35,000",
      price: "90,000",
      profit: "5,000"
    },
    {
      stock_no: "Bus",
      year: "2010",
      make: "1",
      model: "Hino",
      miles: "95,935",
      price: "250,000",
      profit: "29,000"
    },
    {
      stock_no: "Car",
      year: "2015",
      make: "1",
      model: "MG",
      miles: "14,141",
      price: "80,000",
      profit: "7,000"
    }
  ];

  const SearchPresentations = () => {
    console.log(
      value[0].toISOString().split("T")[0],
      value[1].toISOString().split("T")[0],
      stockNumber,
      presenter
    );
    getStats(
      true,
      true,
      "",
      "",
      presenter,
      value[0].toISOString().split("T")[0],
      value[1].toISOString().split("T")[0],
      stockNumber
    );
  };
  const selectionRange = {
    startDate: subDays(new Date(), 90),
    endDate: new Date(),
    key: "selection"
  };
  const handleSelect = date => {
    console.log(date); // native Date object
  };

  const getStats = (
    bStats,
    bDataset,
    pStatus,
    pSeller,
    pPresenter,
    pSDate,
    pEDate,
    pStock
  ) => {
    try {
      Axios.post(
        `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/searchPresentations`,
        {
          p_returnstats: bStats,
          p_returndataset: bDataset,
          p_presentation_status: pStatus,
          p_seller_id: user.user_id,
          p_dealerName: pSeller,
          p_presenter_id: "",
          p_presenter_name: pPresenter,
          p_startDate: pSDate,
          p_endDate: pEDate,
          p_stockno: pStock
        },
        { headers: { "x-access-token": user.accessToken } }
      ).then(response => {
        console.log(response);
        setpresentations(response.data.results[2][0].totalPresentations);
        setcompleted(response.data.results[2][0].completed);
        setpending(response.data.results[2][0].Pending);
        setnetProfit(response.data.results[2][0].profit);
        let temp = [];
        temp.push(["Stock", "Count", { role: "style" }]);
        response.data.results[1].forEach(item =>
          temp.push([item.stock, item.count, ""])
        );
        setChartArray(temp);
        setdata(response.data.results[0]);
        //console.log(temp);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      getStats(
        true,
        true,
        "",
        "",
        "",
        value[0].toISOString().split("T")[0],
        value[1].toISOString().split("T")[0],
        ""
      );
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Box>
        <div className="row">
          <div className="col-md-3">
            <strong>Date Range</strong>
            <DateRangePicker onChange={onChange} value={value} />
          </div>
          <div className="col-md-3">
            <strong>Stock #</strong>
            <input
              className="Form-field"
              type="text"
              name="stockNumber"
              onChange={e => {
                setStockNumber(e.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <strong>Presenter</strong>
            <input
              className="Form-field"
              type="text"
              name="presenter"
              onChange={e => {
                setPresenter(e.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <strong>&nbsp;</strong>
            <input
              onClick={SearchPresentations}
              className="Form-button"
              type="button"
              value="Search"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <SummaryBoxRow
                presentations={presentations}
                netProfit={netProfit}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <Box className="ChartBox">
                  <Chart
                    options={{
                      chartArea: { width: "100%", height: "75%" },
                      legend: { position: "bottom" },
                      colors: [
                        "#12c5d6",
                        "#40d47e",
                        "#ec8f6e",
                        "#ea6153",
                        "#f6c7b6"
                      ]
                    }}
                    chartType="PieChart"
                    data={chart}
                    width={"100%"}
                    height={"300px"}
                  />
                </Box>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <label className="Box-title Box--orange">Products Sold</label>
            <Box>
              <DataTable
                columns={columns}
                data={data1}
                pagination
                customStyles={customStyles}
              />
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default DashboardSeller;
