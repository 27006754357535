import React, { useState, useEffect } from "react";
import Axios from "axios";
import { PropTypes } from "prop-types";
import Box from "../../bootstrap_components/Box";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import MyEmail from "../Email/Email";
import { renderEmail } from "react-html-email";
import * as constantsClass from "../../utils/constants";
import * as sendEmail from "../../utils/sendEmail";
import "dotenv/config";

import AuthService from "../../services/auth.service";
import handleResponse from "../../services/handle-response";
const { REACT_APP_BACKEND_SERVER_URL , REACT_APP_EMAIL_FOOTER} = process.env;


const Users = props => {
  let history = useHistory();
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold"
      }
    }
  };

  const user = AuthService.currentUserValue;
  const [data, setdata] = useState("");
  const [presenterID, setPresenterID] = useState(user.user_id);
  const [presenterName, setPresenterName] = useState(user.username);

  const sendEmailNotification = (fullName, dealerEmail) => {
    try {
      Axios.post(`${REACT_APP_BACKEND_SERVER_URL}/api/send/email`, {
        p_to_email: dealerEmail,
        p_email_subject: "PRESENTATION ACCEPTED",
        p_email_content: `DEAR ${fullName},<br/><br/> YOUR DEAL HAS BEEN ACCEPTED BY PRESENTER <strong> ${user.username}</strong>, PLEASE JOIN THE PRESENATION FOR DEAL COMPLETION <br/><br/><br/>${REACT_APP_EMAIL_FOOTER}`
      }).then(response => {
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  
  const fetchData = async () => {
    Axios.get(`${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Available`, {
      headers: { "x-access-token": user.accessToken }
    })
      .then(response => {
        setdata(response.data.results[0]);
        //console.log(temp);
      })
      .catch(error => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(_content);
        //setContent(_content);

        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status === 403)
        ) {
          AuthService.logout();
          history.push("/login");
          //EventBus.dispatch("logout");
        }
      });
  };
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  // Axios.get("http://localhost:3001/api/Presentation/Available").then
  //     ((response)=>{
  //       setdata(response.data.results[0])
  //     });

  const columns = [
    {
      name: "Customer Name",
      selector: row => row.customer_name
    },
    {
      name: "Stock #",
      selector: row => row.stock_no
    },
    {
      name: "Year",
      selector: row => row.year
    },
    {
      name: "Make",
      selector: row => row.make
    },
    {
      name: "Model",
      selector: row => row.model
    },
    {
      name: "Mileage",
      selector: row => row.miles
    },
    {
      name: "Action",
      cell: row => (
        <input
          className="Form-button"
          type="button"
          value="Accept"
          onClick={() => {
            acceptPresentation(row.id, row.presentation_link, row.full_name, row.user_email);
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];

  // useEffect(() => {
  //   acceptPresentation();
  //     return () => {
  //       setPresentationLink(""); // This worked for me
  //     };
  // }, []);

  const acceptPresentation = async (presentationID, presentationLink, fullName, dealerEmail) => {
    // console.log(presentationLink)
    const responseCheckStatus = await Axios.post(
      `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/CheckStatus`,
      {
        p_order_id: presentationID
      }, {
        headers: { "x-access-token": user.accessToken }
      }
    );

    if (responseCheckStatus.data.success) {
      if (responseCheckStatus.data.details) {
        //if the param details is there
        let presentationdetails = responseCheckStatus.data.details;
        if (presentationdetails.status === 1) {
          // the presentation is still pending someone needs to accept
          const response = await Axios.post(
            `${REACT_APP_BACKEND_SERVER_URL}/api/Presentation/Accept`,
            {
              p_order_id: presentationID,
              p_presenter_id: presenterID
            }, {
              headers: { "x-access-token": user.accessToken }
            }
          );

          if (response.data.success) {
            sendEmailNotification(fullName, dealerEmail);
            let path = `/Presentation/${presentationLink}/${presenterName}`;
            history.push({
              pathname: path,

              state: { p_order_id: presentationID, p_user_role: "Presenter" }
            });
          } else {
            alert(response.data.message);
          }
        } else if (presentationdetails.status === 2) {
          //since this  the presentation is inprogress, check if i am able to join the presentation, if i accepted and left it by chance
          //since this page is only showing presentations with available status we should skip it
        }
      }
    } else {
      alert(responseCheckStatus.data.message);
    }
  };
  return (
    <div>
      <Box>
        <DataTable
          title="Available Presentation"
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </Box>
    </div>
  );
};

export default Users;
