import React from 'react';
import {PropTypes} from "prop-types";
import Box from '../../bootstrap_components/Box';
import formatMoney from '../../utils/format';

const SummaryBoxRow = (props) => {
  const {
    presentations,
    netProfit
  } = props;

  return (
    <><div className="row">
      <div className="col-md-12">
        <Box className="SummaryBox Box--red">
          <h2 className="SummaryBox-title">
            Presentations
          </h2>

          <strong className="SummaryBox-value">
            {presentations || 0}
          </strong>

          <i className="SummaryBox-icon fa fa-list" />
        </Box>
      </div>
    </div>
    <div className="row">
        <div className="col-md-12">
          <Box className="SummaryBox Box--orange">
            <h2 className="SummaryBox-title">
              Net Profit
            </h2>

            <strong className="SummaryBox-value">
              {'$'} {formatMoney(netProfit) || 0}
            </strong>

            <i className="SummaryBox-icon fa fa-dollar" />
          </Box>
        </div>
      </div></>
  );
};

SummaryBoxRow.propTypes = {
  orders: PropTypes.number,
  profit: PropTypes.number,
  emails: PropTypes.number,
  toBeShipped: PropTypes.number
};

export default SummaryBoxRow;
