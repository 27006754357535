import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import Axios from "axios";
import Box from "../../bootstrap_components/Box";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import { render } from "react-dom";
import AuthService from "../../services/auth.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as constantsClass from "../../utils/constants";
import { BehaviorSubject } from "rxjs";
import jwt_decode from "jwt-decode";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";

// import Cookies from "js-cookie";
// import { SessionContext, getSessionCookie, setSessionCookie } from "../../session";
import "dotenv/config";
const { REACT_APP_BACKEND_SERVER_URL } = process.env;
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user"))
);
const Login = props => {
  let history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userID, setUserID] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRoleID, setUserRoleID] = useState("");
  const [userRole, setuserRole] = useState("");

  const redirect = async () => {
    if (params.token) {
      try {
        const response = jwt_decode(params.token, "vdm-secrete-private");
        AuthService.authenticateToken(response).then(response => {
          if (response.data.success) {
            const currentUser = AuthService.currentUserValue;
            if (currentUser && currentUser.homepage) {
              history.push(currentUser.homepage);
            }
          }
        });
      } catch {
        console.error("Invalid token");
      }
    }
  };
  useEffect(() => {
    redirect();
  }, []);
  // const [currentUser,setCurrentUser]= useState(AuthService.currentUserValue);
  // if (currentUser) {
  //   if(currentUser.roles==constantsClass.presenter)
  //                    { history.push('/PresentationAvailable');
  //                   }
  //                   if(currentUser.roles==constantsClass.seller)
  //                    { history.push('/Dashboard');
  //                   }
  // }

  return (
    <>
      {loading ? (
        <div style={{ "text-align": "center" }}>
          <BeatLoader color="#333333" loading margin={20} size={50} />
        </div>
      ) : (
        <div>
          <h3>Login</h3>
          <Formik
            initialValues={{
              username: "",
              password: ""
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .required("Required")
                .min(6, "Too Short! Min 6 characters")
                .max(50, "Too Long!")
                .matches(
                  /^[a-zA-Z0-9.]*$/,
                  "Can only contain letters, digits and ."
                ),
              password: Yup.string()
                .required("Required")
                .min(6, "Too Short!")
                .max(20, "Too Long!")
                .matches(
                  /^[a-zA-Z0-9@]*$/,
                  "Can only contain letters, digits and @"
                )
            })}
            onSubmit={(
              { username, password },
              { setStatus, setSubmitting }
            ) => {
              setStatus();
              AuthService.authenticateUser(username, password).then(
                response => {
                  if (response.data.success) {
                    setSubmitting(false);
                    const currentUser = AuthService.currentUserValue;
                    if (currentUser && currentUser.homepage) {
                      history.push(currentUser.homepage);
                    }
                  } else {
                    setSubmitting(false);
                    setStatus(response.data.message);
                  }
                },
                error => {
                  setSubmitting(false);
                  setStatus(error);
                }
              );
            }}
            render={({ errors, status, touched, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Field
                    name="username"
                    type="text"
                    className={
                      "form-control" +
                      (errors.username && touched.username ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Login
                  </button>

                  {isSubmitting && (
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  )}
                  <p className="forgot-password pull-right">
                    <Link to="/ForgotPassword">Forgot Password?</Link>
                  </p>
                  <p className="forgot-password pull-right">
                    <Link to="/Register">Register</Link>&nbsp;&nbsp;
                  </p>
                  <p className="forgot-password pull-right">
                    <Link to="/Privacy">Privacy Policy</Link>&nbsp;&nbsp;
                  </p>
                </div>

                {status && <div className={"alert alert-danger"}>{status}</div>}
              </Form>
            )}
          />
        </div>
      )}
    </>
  );
};

export default Login;
