import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router";
import cx from "classnames";
import Menu from "../Menu";
import ActionBar from "../ActionBar";

import "./sidenav.scss";
import AuthService from "../../services/auth.service";
import * as constantsClass from "../../utils/constants";

const SideNav = props => {
  const user = AuthService.currentUserValue;
  const isSeller = user.roles == constantsClass.seller ? true : false;
  const isPresenter = user.roles == constantsClass.presenter ? true : false;
  const isOwner = user.roles == constantsClass.owner ? true : false;

  const menuItems = [
    {
      title: "Dashboard",
      link: "/Dashboard",
      faClass: "fa-dashboard"
    },
    {
      title: "Create Presentation",
      link: "/CreatePresentation",
      faClass: "fa-pencil" // Font awesome class
      // count: 15 Notification count
    },
    {
      title: "All Presentations",
      link: "/AllPresentations",
      faClass: "fa-list"
    },
    {
      title: "Report",
      link: "/ReportSeller",
      faClass: "fa-file"
    }
  ];

  const menuItems2 = [
    {
      title: "Presentation Available",
      link: "/PresentationAvailable",
      faClass: "fa-dashboard"
    },
    {
      title: "Left Presentations",
      link: "/PendingPresentations",
      faClass: "fa-list"
    } //,
    // {
    //   title: 'Presentation',
    //   link: '/Presentation',
    //   faClass: 'fa-list', // Font awesome class
    // },
    // {
    //   title: 'All tasks',
    //   link: '/Tasks',
    //   faClass: 'fa-list',
    //   count: 253
    // }
  ];
  const menuItems3 = [
    {
      title: "Dashboard",
      link: "/OwnerDashboard",
      faClass: "fa-dashboard"
    },
    {
      title: "Report",
      link: "/ownerReport",
      faClass: "fa-file"
    },
    {
      title: "Activate User",
      link: "/ActivateUser",
      faClass: "fa-list"
    },
    {
      title: "Block User",
      link: "/BlockUser",
      faClass: "fa-list"
    }
  ];

  return (
    <nav className={cx("SideNav", props.customClass)}>
      <span className="SideNav-companyLogo">VDM</span>

      <Menu items={menuItems} caption="Seller" display={isSeller} />

      <Menu items={menuItems2} caption="Presenter" display={isPresenter} />

      <Menu items={menuItems3} caption="Admin" display={isOwner} />

      {/* <ActionBar /> */}
    </nav>
  );
};

SideNav.propTypes = {
  customClass: PropTypes.string
};

export default SideNav;
